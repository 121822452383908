import Vue from 'vue'
import VueRouter from 'vue-router'
import VSheet from '../components/VSheet.vue'
import auth from '../../auth/auth'

Vue.use(VueRouter)
const router = new VueRouter({
    mode: 'history',
    // base: process.env.BASE_URL,
    base: '/',
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: [
        {
            path: '*',
            name: 'login',
            component: () => import('../components/Login.vue'),
            meta: {
              requiresAuth: false,
            }
        },
        {
            path: '/sheet-integration',
            name: 'vsheet',
            component: VSheet,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: '/test-chart',
            name: 'test-chart',
            component: () => import('../components/TestChart.vue'),
            meta: {
                requiresAuth: true,
            }
        },
        {
          path: '/workspace-connections',
          name: 'workspaceconnections',
          component: () => import('../components/WorkspaceConnections.vue'),
          meta: {
              requiresAuth: true,
          }
      },
        {
            path: '/dashboard-connections',
            name: 'dashboard-connections',
            component: () => import('../components/DashboardConnection.vue'),
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: '/connectors',
            name: 'connectors',
            component: () => import('../components/Connectors.vue'),
            meta: {
                requiresAuth: true,
            }
        },
        {
          path: '/visualisation',
          name: 'Vtool',
          component: () => import('../components/Vtool.vue'),
          meta: {
              requiresAuth: true,
          }
        },
        {
          path: '/reset-password',
          name: 'ResetPassword',
          component: () => import('../components/ResetPassword.vue'),
          meta: {
              requiresAuth: false,
          }
        },
        {
          path: '/sso-login/:token',
          name: 'sso-login',
          component: () => import('../components/SsoLogin.vue'),
          meta: {
              requiresAuth: false,
          }
        },
        {
          path: '/publish/:PublishDashboard*',
          name: 'PublishDashboard',
          component: () => import('../components/PublishDashboard.vue'),
          meta: {
              requiresAuth: false,
          }
        },

        // org urls
        {
          path: '/department',
          name: 'Department',
          component: () => import('../components/org/department/list-department.vue'),
              meta: {
                  requiresAuth: false,
              }
        },
        {
          path: '/role',
          name: 'Role',
          component: () => import('../components/org/role/list-role.vue'),
              meta: {
                  requiresAuth: false,
              }
        },
        {
          path: '/group',
          name: 'Group',
          component: () => import('../components/org/group/list-group.vue'),
              meta: {
                  requiresAuth: false,
              }
        },
        {
          path: '/employee',
          name: 'Employee',
          component: () => import('../components/org/employee/list-employee.vue'),
              meta: {
                  requiresAuth: false,
              }
        },
        {
          path: '/settings',
          component: () => import('../components/org/permissions/workspace.vue'),
          children: [
            {
              path: '/settings/permissions',
              name: 'Permissions',
              component: () => import('../components/org/permissions/workspace.vue'),
              meta: {
                  requiresAuth: false,
              }
            },
            {
              path: '/logs',
              name: 'Logs',
              component: () => import('../components/org/permissions/workspace.vue'),
              meta: {
                  requiresAuth: false,
              }
            }
          ]
        }
        
    ],


})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (!auth.isAuthenticated()) {
        next({
          name: 'login',
          query: { redirect: to.fullPath }
        })
      next()
      } else {
        next()
      }
    } else {
      next()
    }
  })

export default router