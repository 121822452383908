import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {ColorPicker, ColorPanel} from 'one-colorpicker'
import router from './router/router'
import VueApexCharts from 'vue-apexcharts'
import VueFileAgent from 'vue-file-agent';
import apolloProvider from './apollo';
import axios from 'axios'
// import axios from './libs/axios.js'
import VueAxios from 'vue-axios'
import {Pivot} from 'vue-webdatarocks';
import ProgressBar from 'vuejs-progress-bar';
import vSelect from 'vue-select'
import DateRangePicker from 'vue2-daterange-picker'


Vue.use(ProgressBar)

Vue.config.productionTip = false

Vue.prototype.$axios = axios

Vue.prototype.$hostname = ''

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueApexCharts)
Vue.use(VueFileAgent)
Vue.use(VueAxios, axios)
Vue.use(ColorPanel)
Vue.use(ColorPicker)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('VueApexCharts', VueApexCharts)
Vue.component('Pivot', Pivot)
Vue.component('v-select', vSelect)
Vue.component('date-range-picker', DateRangePicker)

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-file-agent/dist/vue-file-agent.css';
import './assets/css/custom.scss'
import './assets/css/style.scss'
import 'webdatarocks/webdatarocks.css';
import 'vue-select/dist/vue-select.css';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';


new Vue({
  router,
  apolloProvider,
  render: h => h(App),
}).$mount('#app')
