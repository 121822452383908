import Vue from 'vue';
import VueApollo from 'vue-apollo';
import ApolloClient from 'apollo-boost';

let setheader="";
if(localStorage.getItem('token')!=null){
  setheader= `Bearer ${localStorage.getItem('token')}`;
}

// Create an instance of ApolloClient
const apolloClient = new ApolloClient({
  headers: {
    authorization: setheader
  },
  // Replace 'http://your-graphql-endpoint' with the actual GraphQL API endpoint
  // uri: '/graphql/',
    uri:'/graphql/'
});

// Install the plugin
Vue.use(VueApollo);

// Create a new instance of VueApollo and provide the ApolloClient instance
const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

// Export the ApolloProvider instance
export default apolloProvider;