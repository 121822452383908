<template>
    <div v-bind:class="showFilterModal ? 'blurbackground' : ''">
        <div class="toolbar__top">
            <!-- <div class="tool__icon"><font-awesome-icon icon="fa-solid fa-rotate-left" /></div>
                    <div class="bor__right tool__icon"><font-awesome-icon icon="fa-solid fa-rotate-right" /></div> -->
            <!-- <div class="tool__icon"><font-awesome-icon icon="fa-solid fa-layer-group" /></div>
            <div class="tool__icon"><font-awesome-icon icon="fa-solid fa-gear" /></div>
            <div class="bor__right tool__icon"><font-awesome-icon icon="fa-solid fa-download" /></div>
            <div class="tool__icon"><font-awesome-icon icon="fa-solid fa-image" /></div>
            <div class="tool__icon"><font-awesome-icon icon="fa-solid fa-expand" /></div> -->
            <div class="tool__icon">
                <font-awesome-icon icon="fa-solid fa-palette" @click="opencolorpalate = !opencolorpalate" />
                <div class="tool__navbar color__changes" v-show="opencolorpalate">
                    <span class="shap_nav"></span>
                    <color-panel v-model="colorpicker"></color-panel>
                    <b-button class="btn-primary btn mt-2" @click="addColors(colorpicker)">Add</b-button>
                </div>
            </div>
            <!-- <div class="bor__right tool__icon"><font-awesome-icon icon="fa-solid fa-list-ul" /></div> -->
            <div class="tool__icon" v-if="sheetsData[currentActiveSheetIndex].currentdatasource != null">
                <font-awesome-icon icon="fa-solid fa-dollar-sign" @click="checknumcurrency(true)" />
                <div v-if="showcurrencyformat" class="ascending__modal">
                    <h6>Change currency : <font-awesome-icon icon="fa-solid fa-xmark" class="calfildedt"
                            @click="showcurrencyformat = !showcurrencyformat" /></h6>
                    <div class="getcurshtval">
                        <p v-for="(currdta, curridx) in sheetsData[currentActiveSheetIndex].getcurrencynumdata"
                            :key="curridx">
                            {{ currdta.field }}
                            <b-form-select v-model="currdta.format" @change="currencychange(true)">
                                <b-form-select-option value="select">Select</b-form-select-option>
                                <b-form-select-option value="indian">indian</b-form-select-option>
                                <b-form-select-option value="english">english</b-form-select-option>
                            </b-form-select>
                        </p>
                    </div>
                </div>
            </div>
            <div class="tool__icon position-relative">
                <font-awesome-icon icon="fa-solid fa-sort" @click="ascendingorder(true)" />
                <div class="ascending__modal" v-if="shwascdsc">
                    <h6>Sort Measures : <font-awesome-icon icon="fa-solid fa-xmark" class="calfildedt"
                            @click="shwascdsc = !shwascdsc" /></h6>
                    <div class="getcurshtval">
                        <p v-for="(shtdta, shtidx) in sheetsData[currentActiveSheetIndex].getcurrentshtdim" :key="shtidx"
                            :class="[shtdta.sort != null ? 'activeascendorder' : '']" @click="getascdscorder(shtdta)">
                            <span><font-awesome-icon icon="fa-solid fa-hashtag" />
                                {{ shtdta.name }}</span><span><font-awesome-icon icon="fa-solid fa-arrow-up-short-wide"
                                    v-if="shtdta.sort == 'asc' || shtdta.sort == null" /><span class="ascdsclbl"
                                    v-if="shtdta.sort == 'asc'">- Asc</span><font-awesome-icon
                                    icon="fa-solid fa-arrow-up-wide-short" v-if="shtdta.sort == 'dsc'" /><span
                                    class="ascdsclbl" v-if="shtdta.sort == 'dsc'">- Dsc</span></span>
                        </p>
                    </div>
                </div>
            </div>
            <!-- <div class="tool__icon"><font-awesome-icon icon="fa-solid fa-magnifying-glass" /></div>

            <div class="tool__icon"><font-awesome-icon icon="fa-solid fa-code" /></div> -->
            <div class="tool__icon" title="calculated fields" @click="opncalender">
                <font-awesome-icon icon="fa-solid fa-calculator" />
            </div>
            <b-modal id="calculated-fields" v-model="showcalculatedmodal" v-if="showcalculatedmodal" centered hide-footer
                title="Calculated Value">
                <div>
                    <label>Type field name :</label>
                    <b-input type="text" v-model="calfieldnme" class="mb-3"></b-input>
                    <label>Select and Drag measures :</label>
                    <div class="calculatedfieldslist mb-3">
                        <b-input placeholder="Search for measures..."
                            v-if="getsheetdatasource.data.getDataSource.numericalDimensions.length > 7"
                            class="filter_input mt-2"
                            @input="filterdashboardsheets($event, 'active_calculated_numeric_fields')"
                            autocomplete="off"></b-input>
                        <b-list-group class="active__column mt-2" id="active_calculated_numeric_fields">
                            <b-list-group-item @dragstart="dragStart" draggable="true" :id="'num_' + dumindex"
                                data-type="numerical"
                                v-for="(dumdata, dumindex) in getsheetdatasource.data.getDataSource.numericalDimensions"
                                :key="dumindex" :title="dumdata"><font-awesome-icon icon="fa-solid fa-hashtag" /><span>{{
                                    dumdata }}</span></b-list-group-item>
                        </b-list-group>
                    </div>
                    <label>Select and Drag Operators :</label>
                    <div class="operatorgroup__list mb-3">
                        <div v-for="(opr, opind) in operatorlist" :key="'opr' + opind" class="opr__lst"
                            @dragstart="dragStart" draggable="true" data-field="operator">{{ opr }}</div>
                        <div v-for="(con, conind) in numercallist" :key="'con' + conind" class="arith__lst"
                            @dragstart="dragStart" draggable="true" data-field="number">{{ con }}</div>
                    </div>
                    <div id="calculated_fields_value" @drop.prevent="dropcalculated($event)" @dragenter.prevent
                        @dragover.prevent>
                        <span v-for="(formobj, formidx) in getformulafields" :key="'formula' + formidx"
                            :class="[formobj.operator ? 'oprtrvalue' : 'feldvalue']"
                            :data-operatorstatus="formobj.operator">
                            <label v-if="!formobj.input" :data-numberstatus="formobj.number"
                                @click="editcalculatedtexteditor(formobj)">{{ formobj.name }} <font-awesome-icon
                                    icon="fa-regular fa-trash-can" @click="getformulafields.splice(formidx, 1)" /></label>
                            <div class="inpbxtxtedtr" v-if="formobj.input"><b-form-input type="text" v-model="formobj.name"
                                    class="numberonlyinp" @blur="formobj.input = false" @keyup.enter="formobj.input = false"
                                    @keypress="NumbersOnly($event)"
                                    @input="replacecalfild(formobj, formobj.name)"></b-form-input><font-awesome-icon
                                    icon="fa-regular fa-circle-right" @click="formobj.input = false" /></div>
                            <div class="searchcaleditorfield" v-if="formobj.suggest && !formobj.mathmatical">
                                <b-input placeholder="Search for measures..."
                                    v-if="getsheetdatasource.data.getDataSource.numericalDimensions.length > 7"
                                    class="filter_input mt-2"
                                    @input="filterdashboardsheets($event, 'active_calculated_numeric_suggest_fields')"
                                    autocomplete="off"></b-input>
                                <b-list-group class="active__column mt-2" id="active_calculated_numeric_suggest_fields">
                                    <b-list-group-item :id="'suggnum_' + suggdumindex" data-type="numerical"
                                        v-for="(suggdumdata, suggdumindex) in getsheetdatasource.data.getDataSource.numericalDimensions"
                                        :key="suggdumindex" :title="suggdumdata"
                                        @click="replacecalfild(formobj, suggdumdata)"><font-awesome-icon
                                            icon="fa-solid fa-hashtag" /><span>{{
                                                suggdumdata }}</span></b-list-group-item>
                                </b-list-group>
                            </div>
                            <div class="searchcaleditorfield" v-if="formobj.mathmaticalsuggest">
                                <b-input placeholder="Search for measures..." class="filter_input mt-2"
                                    @input="filterdashboardsheets($event, 'active_calculated_operator_suggest_fields')"
                                    autocomplete="off"></b-input>
                                <b-list-group class="active__column mt-2" id="active_calculated_operator_suggest_fields">
                                    <b-list-group-item :id="'oprtr_' + opind" v-for="(opr, opind) in operatorlist"
                                        :key="opind" :title="opr" @click="replacecalfild(formobj, opr)"><span>{{
                                            opr }}</span></b-list-group-item>
                                </b-list-group>
                            </div>
                        </span>
                    </div>
                    <b-button class="btn btn-lg btn-primary mb-3" v-if="appupsts" @click="createcalfield">Apply</b-button>
                    <b-button class="btn btn-lg btn-primary mb-3" v-else @click="updatecalfield">Update</b-button>
                </div>
            </b-modal>
        </div>
        <div class="visualisation__tool">
            <div class="data__configuration__sidebar">
                <h4 class="position__dtasrc">
                    <font-awesome-icon icon="fa-solid fa-database" /> Datasources
                    <font-awesome-icon icon="fa-solid fa-circle-info" class="infolst"
                        @click="toggleinfo('datasrcinfotoggle')" v-if="getsheetdatasource.data.getDataSource.name != ''" />
                    <div id="datasrcinfotoggle" class="toggle_d_none d-none">
                        <h5>Datasource Information <font-awesome-icon icon="fa-solid fa-xmark" class="calfildedt ms-2"
                                @click="toggleinfo('datasrcinfotoggle')" /><font-awesome-icon
                                icon="fa-regular fa-pen-to-square" class="calfildedt" v-if="infolstdta"
                                @click="infolstdta = !infolstdta" /><font-awesome-icon icon="fa-regular fa-floppy-disk"
                                class="calfildedt" @click="savedatasrcinfo" v-else /></h5>
                        <div v-if="infolstdta" class="scrl_desc">
                            <span class="adddeschere"
                                v-if="getdatasrcdescription == null || getdatasrcdescription.length == 0"
                                @click="infolstdta = !infolstdta">Add description here...</span>
                            <p v-else>{{ getdatasrcdescription }}</p>
                        </div>
                        <div v-else>
                            <b-form-textarea v-model="getdatasrcdescription" placeholder="Enter description here..."
                                rows="3"></b-form-textarea>
                        </div>
                    </div>
                </h4>
                <b-form-select v-model="sheetsData[currentActiveSheetIndex].currentdatasource" class="datasourceselect"
                    @change="datasourcechange($event)">
                    <b-form-select-option :value="src.datasource" v-for="(src, srcidx) in getallsourceslist"
                        :key="srcidx">{{
                            src.datasource }}</b-form-select-option>
                </b-form-select>

                <h4 @click="opennxtdrawer('dimcatlst')"><font-awesome-icon icon="fa-solid fa-grip-vertical" /> Dimensions
                    <b-icon icon="caret-down-square" class="float-end"></b-icon>
                </h4>
                <div class="loader__tools" v-if="loader_status">
                    <Loadertool_vue />
                </div>
                <div class="heig0lst hei0" id="dimcatlst" v-else>
                    <b-input placeholder="Search for dimensions..."
                        v-if="getsheetdatasource.data.getDataSource.categoricalDimensionsInfo.length > 7"
                        class="filter_input mt-2" @input="filterdashboardsheets($event, 'active_dimension_group')"
                        autocomplete="off"></b-input>
                    <b-list-group class="active__column mt-2" id="active_dimension_group">
                        <b-list-group-item @dragstart="dragStart" draggable="true" :id="'catind_' + dumindex"
                            data-type="categories" :date-type="dumdata.type"
                            v-for="(dumdata, dumindex) in getsheetdatasource.data.getDataSource.categoricalDimensionsInfo"
                            :key="dumindex" :title="dumdata.name"><font-awesome-icon icon="fa-regular fa-file-lines" /><span
                                class="txtlmt">{{
                                    dumdata.name
                                }}</span>
                            <font-awesome-icon icon="fa-solid fa-retweet" title="Convert into Measures" class="convdm"
                                @click="convertdimandmeasures(dumdata, dumindex)" />
                            <font-awesome-icon icon="fa-solid fa-circle-info" @click="toggleinfo('catinfo' + dumindex)"
                                class="infolst" />
                            <div :id="'catinfo' + dumindex" class="toggle_d_none d-none">
                                <h5>{{ dumdata.name }} <font-awesome-icon icon="fa-solid fa-xmark" class="calfildedt ms-2"
                                        @click="toggleinfo('catinfo' + dumindex)" /><font-awesome-icon
                                        icon="fa-regular fa-pen-to-square" class="calfildedt" v-if="infolstdta"
                                        @click="infolstdta = !infolstdta" /><font-awesome-icon
                                        icon="fa-regular fa-floppy-disk" class="calfildedt" @click="savedimandmesdata"
                                        v-else /></h5>
                                <div v-if="infolstdta" class="scrl_desc">
                                    <span v-if="dumdata.info == null || dumdata.info.length == 0" class="adddeschere"
                                        @click="infolstdta = !infolstdta">Add description here...</span>
                                    <p v-else>{{ dumdata.info }}</p>
                                </div>
                                <div v-else>
                                    <b-form-textarea v-model="dumdata.info" placeholder="Enter description here..."
                                        rows="3"></b-form-textarea>
                                </div>
                            </div>
                        </b-list-group-item>
                    </b-list-group>
                </div>
                <hr>
                <h4 @click="opennxtdrawer('meascatlst')"><font-awesome-icon icon="fa-solid fa-grip-vertical" /> Measures
                    <b-icon icon="caret-down-square" class="float-end"></b-icon>
                </h4>
                <div class="loader__tools" v-if="loader_status">
                    <Loadertool_vue />
                </div>
                <div class="heig0lst hei0" id="meascatlst" v-else>
                    <b-input placeholder="Search for measures..."
                        v-if="getsheetdatasource.data.getDataSource.numericalDimensionsInfo.length > 7"
                        class="filter_input mt-2" @input="filterdashboardsheets($event, 'active_measures_group')"
                        autocomplete="off"></b-input>
                    <b-list-group class="inactive__column mt-2" id="active_measures_group">
                        <b-list-group-item @dragstart="dragStart" draggable="true" :id="'num_' + dumindex"
                            data-type="numerical"
                            v-for="(dumdata, dumindex) in getsheetdatasource.data.getDataSource.numericalDimensionsInfo"
                            :key="dumindex" :title="dumdata.name"><font-awesome-icon icon="fa-solid fa-hashtag" /><span
                                class="txtlmt">{{ dumdata.name
                                }}</span>
                            <font-awesome-icon icon="fa-solid fa-retweet" @click="convertdimandmeasures(dumdata, dumindex)"
                                title="Convert into Dimensions" class="convdm" />
                            <font-awesome-icon icon="fa-solid fa-circle-info" @click="toggleinfo('numinfo' + dumindex)"
                                class="infolst" />
                            <div :id="'numinfo' + dumindex" class="toggle_d_none d-none tp300">
                                <h5>{{ dumdata.name }} <font-awesome-icon icon="fa-solid fa-xmark" class="calfildedt ms-2"
                                        @click="toggleinfo('numinfo' + dumindex)" /><font-awesome-icon
                                        icon="fa-regular fa-pen-to-square" class="calfildedt" v-if="infolstdta"
                                        @click="infolstdta = !infolstdta" /><font-awesome-icon
                                        icon="fa-regular fa-floppy-disk" class="calfildedt" @click="savedimandmesdata"
                                        v-else /></h5>
                                <div v-if="infolstdta" class="scrl_desc">
                                    <span v-if="dumdata.info == null || dumdata.info.length == 0" class="adddeschere"
                                        @click="infolstdta = !infolstdta">Add description here...</span>
                                    <p v-else>{{ dumdata.info }}</p>
                                </div>
                                <div v-else>
                                    <b-form-textarea v-model="dumdata.info" placeholder="Enter description here..."
                                        rows="3"></b-form-textarea>
                                </div>
                            </div>
                        </b-list-group-item>
                    </b-list-group>
                </div>
                <hr>
                <h4 @click="opennxtdrawer('calcatlst')"><font-awesome-icon icon="fa-solid fa-grip-vertical" /> Calculated
                    fields <b-icon icon="caret-down-square" class="float-end"></b-icon></h4>
                <div class="loader__tools" v-if="loader_status">
                    <Loadertool_vue />
                </div>
                <div class="heig0lst hei0" id="calcatlst" v-else>
                    <b-input placeholder="Search for fields..." v-if="formulasheetlisting.length > 7"
                        class="filter_input mt-2" @input="filterdashboardsheets($event, 'active_calculated_group')"
                        autocomplete="off"></b-input>
                    <b-list-group class="inactive__column mt-2" id="active_calculated_group">
                        <b-list-group-item @dragstart="dragStart" draggable="true" :id="'cal_' + calindex"
                            data-type="numerical" data-field="calculated" v-for="(caldata, calindex) in formulasheetlisting"
                            :key="calindex" :title="caldata.name"><font-awesome-icon icon="fa-solid fa-calculator" /><span
                                class="txtlmt">{{
                                    caldata.name
                                }}</span>
                            <font-awesome-icon icon="fa-solid fa-trash-can" class="calfildlst"
                                @click="clearcalculatedfield(caldata, calindex)" />
                            <font-awesome-icon icon="fa-regular fa-pen-to-square"
                                @click="editcalculatedfield(caldata, calindex)" class="calfildedt" />
                        </b-list-group-item>
                    </b-list-group>
                </div>
            </div>
            <div class="toolbar__sidebar">
                <h6>Graph View :</h6>
                <div class="graph__toolbar">
                    <div class="loader__tools" v-if="loader_status">
                        <Loadertool_vue />
                    </div>
                    <div class="graph__view__list" title="Pivot Table" data-id="pivot" @dragstart="draggraphtypestart"
                        draggable="true" @click="changegraphtype('pivot')" v-show="!loader_status && pivotdm"><img
                            src="../assets/img/pivot.png" data-id="pivot" alt="pivot">
                    </div>
                    <div class="graph__view__list" title="Table" data-id="table" @dragstart="draggraphtypestart"
                        draggable="true" @click="changegraphtype('table')" v-show="!loader_status && tabledm"><img
                            src="../assets/img/table.png" data-id="table" alt="table">
                    </div>
                    <div class="graph__view__list" title="Horizontal Column" data-id="bar" @dragstart="draggraphtypestart"
                        draggable="true" @click="changegraphtype('bar', 'horizontal')" v-show="!loader_status && barhdm">
                        <img src="../assets/img/bar-chart-h.png" data-id="bar" alt="bar-chart-h">
                    </div>
                    <div class="graph__view__list" title="Vertical Column" data-id="bar" @dragstart="draggraphtypestart"
                        draggable="true" @click="changegraphtype('bar', 'vertical')" v-show="!loader_status && barvdm"><img
                            src="../assets/img/bar-chart-v.png" data-id="bar" alt="bar-chart-v">
                    </div>
                    <div class="graph__view__list" title="Donut" data-id="pie" @dragstart="draggraphtypestart"
                        draggable="true" @click="changegraphtype('pie')" v-show="!loader_status && piedm"><img
                            src="../assets/img/pie.png" data-id="pie" alt="pie">
                    </div>
                    <div class="graph__view__list" title="Line" data-id="line" @dragstart="draggraphtypestart"
                        draggable="true" @click="changegraphtype('line')" v-show="!loader_status && linedm"><img
                            src="../assets/img/line-chart.png" data-id="line" alt="line-chart">
                    </div>
                    <div class="graph__view__list" title="Scatter" data-id="scatter" @dragstart="draggraphtypestart"
                        draggable="true" @click="changegraphtype('scatter')" v-show="!loader_status && scatterdm"><img
                            src="../assets/img/scatter.png" data-id="scatter" alt="scatter">
                    </div>
                    <!-- <div class="graph__view__list" title="Tree" data-id="tree" @dragstart="draggraphtypestart"
                                draggable="true" @click="changegraphtype('tree')" v-show="treedm"><img
                                    src="../assets/img/tree.png" data-id="tree" alt="tree">
                            </div>
                            <div class="graph__view__list" title="Gantt" data-id="gantt" @dragstart="draggraphtypestart"
                                draggable="true" @click="changegraphtype('gantt')" v-show="ganttdm"><img
                                    src="../assets/img/gantt-chart.png" data-id="gantt" alt="gantt">
                            </div>
                            <div class="graph__view__list" title="KPI" data-id="kpi" @dragstart="draggraphtypestart"
                                draggable="true" @click="changegraphtype('line')" v-show="kpidm"><img
                                    src="../assets/img/kpi.png" data-id="kpi" alt="kpi">
                            </div> -->
                </div>

                <h6>Filters :</h6>
                <div class="graph__toolbar d-block">
                    <div class="loader__tools" v-if="loader_status">
                        <Loadertool_vue />
                    </div>
                    <div class="filters__list" @drop.prevent="dropfilter($event)" @dragenter.prevent @dragover.prevent>
                        <span v-for="(fdata, findex) in sheetsData[currentActiveSheetIndex].filterlist" :key="findex"
                            class="xspandata">
                            <div @click="filtermodal(fdata)" class="dfull">{{ fdata }}</div><font-awesome-icon
                                icon="fa-regular fa-circle-xmark" @click="clearfilterlist(findex)" />
                        </span>
                    </div>
                    <b-modal id="modal-center" v-model="showFilterModal" centered hide-footer title="Filter">
                        <Loadertool_vue v-if="sheetsData[currentActiveSheetIndex].fildtalod" />
                        <div v-else>
                            <p><b>Field :</b></p>
                            <div class="list_of_filternames">
                                <span>{{ sheetsData[currentActiveSheetIndex].dropfilterdata.name }}</span>
                            </div>
                            <b-tabs>
                                <b-tab title="One-Of" v-if="oneofsts">
                                    <div class="mt-3 filterheightoverflow">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th style="width: 50px;">Visible</th>
                                                    <th>Label</th>
                                                    <th style="width: 50px;">Count</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(ele, indn) in sheetsData[currentActiveSheetIndex].dropfilterdata.data"
                                                    :key="indn">
                                                    <td style="text-align: center;">
                                                        <b-form-group>
                                                            <b-form-checkbox-group
                                                                v-model="sheetsData[currentActiveSheetIndex].dropfilterdata.selectfilterlist">
                                                                <b-form-checkbox
                                                                    :value="ele[sheetsData[currentActiveSheetIndex].dropfilterdata.name]"></b-form-checkbox>
                                                            </b-form-checkbox-group>
                                                        </b-form-group>
                                                    </td>
                                                    <td>{{
                                                        ele[sheetsData[currentActiveSheetIndex].dropfilterdata.name]
                                                    }}
                                                    </td>
                                                    <td style="text-align: center;">{{ ele.count }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <b-button
                                        @click="selectallfilter(sheetsData[currentActiveSheetIndex].dropfilterdata.name)">Select
                                        All</b-button>
                                    <b-button
                                        @click="reverseSelection(sheetsData[currentActiveSheetIndex].dropfilterdata.data, sheetsData[currentActiveSheetIndex].dropfilterdata.selectfilterlist, sheetsData[currentActiveSheetIndex].dropfilterdata.name)">Reverse
                                        Selection</b-button>
                                </b-tab>
                                <b-tab title="Range" v-if="filternumtab">
                                    <div class="py-5 ps-3">
                                        <HistogramSlider :width="400" :bar-height="0" :data="rangesliderdata"
                                            :drag-interval="true" :force-edges="false" :colors="['#4facfe', '#00f2fe']"
                                            :min="filterminrange" :max="filtermaxrange" @finish="getrangevalue($event)" />
                                    </div>
                                </b-tab>
                                <b-tab title="Date" v-if="datefiltertoggle">
                                    <div class="py-3">
                                        <b-button @click="createdatetimetoggle"
                                            :class="[timpickersts ? 'btn-danger' : '', 'mb-2 me-0']"><span
                                                v-if="!timpickersts">Time Enable <font-awesome-icon
                                                    icon="fa-regular fa-circle-check" class="chktimsts" /></span> <span
                                                v-if="timpickersts">Time Disable <font-awesome-icon
                                                    icon="fa-regular fa-circle-xmark" class="chktimsts" /></span></b-button>
                                        <date-range-picker v-model="dateRange" :showDropdowns="true"
                                            :locale-data="{ separator: ' to ', format: localtimests }"
                                            :timePicker="timpickersts" />
                                    </div>
                                </b-tab>
                            </b-tabs>
                            <hr>
                            <b-button pill variant="success" class="me-2"
                                @click="confirmfilter(sheetsData[currentActiveSheetIndex].dropfilterdata.selectfilterlist, currentActiveSheetIndex)">Confirm</b-button>
                            <b-button pill variant="outline-secondary"
                                @click="showFilterModal = !showFilterModal">Cancel</b-button>
                        </div>
                    </b-modal>
                    <!-- <div class="graph__view__list" title="Colors" data-id="colors">
                                <b-form-input type="color" v-model="sheetsData[0].c1chartOptions.colors" :value="sheetsData[0].c1chartOptions.colors"></b-form-input>
                            </div> -->
                </div>
                <h6 v-if="sheetsData[currentActiveSheetIndex].graphtype == 'pivot'">Pivot Values :</h6>
                <div class="graph__toolbar d-block" v-if="sheetsData[currentActiveSheetIndex].graphtype == 'pivot'">
                    <div class="loader__tools" v-if="loader_status">
                        <Loadertool_vue />
                    </div>
                    <div class="filters__list" @drop.prevent="droppivotvalue($event)" @dragenter.prevent @dragover.prevent>
                        <span v-for="(gamd, gamdindx) in sheetsData[currentActiveSheetIndex].getallmeasuresdata"
                            :key="gamdindx" class="xspandata xyspandata">{{ gamd.name }} <span class="svgagg"
                                @click="openaggregatordrop2($event)" v-if="gamd.aggregation">f(x)</span>
                            <font-awesome-icon icon="fa-regular fa-circle-xmark"
                                @click="cleardroppivotlist(gamdindx, gamd.name)" />
                            <div v-if="gamd.aggregation" class="aggregationdropdown d-none">
                                <b-form-select v-model="gamd.aggregation"
                                    @change="updatedropdown($event, currentActiveSheetIndex, gamd.name)" size="sm">
                                    <b-form-select-option :value="valagg" v-for="(valagg, valaggindx) in aggregatorslist"
                                        :key="valaggindx">{{ valagg
                                        }}</b-form-select-option>
                                </b-form-select>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
            <div class="result__panel">
                <div class="loader__result" v-if="loader_status">
                    <Loader_vue />
                </div>
                <b-card class="scrltab" no-body>
                    <b-tabs card end fixed @input="tabswitch(shttbindx)" v-model="shttbindx">
                        <!-- Render Tabs, supply a unique `key` to each tab -->
                        <b-tab v-for="(sheetItem, index) in sheetsData" :key="'dyn-tab-' + (index + 1)">
                            <template #title>
                                <span @dblclick="renamesheet()" class="d-flex align-items-center"><font-awesome-icon
                                        icon="fa-regular fa-note-sticky" /> <span v-if="!sheetItem.showRenamestatus"
                                        class="ms-1"> {{ sheetItem.renametext }} </span> <b-input class="rename_input"
                                        @keyup.enter="saverename"
                                        @keyup.space="(e) => { sheetItem.renametext = e.target.value + e.key }"
                                        v-model="sheetItem.renametext" v-if="sheetItem.showRenamestatus"
                                        @blur="saverename"></b-input></span>
                            </template>
                            <b-row class="fixedsaverow">
                                <b-col md="12" class="text-end">
                                    <b-button size="sm" variant="success" class="mb-2 me-2" @click="saveTab(index)">
                                        <font-awesome-icon icon="fa-regular fa-floppy-disk" /> Save
                                    </b-button>
                                    <b-button size="sm" variant="danger" class="mb-2"
                                        @click="closeTab(index, sheetItem.sheetID)">
                                        <font-awesome-icon icon="fa-solid fa-delete-left" /> Delete
                                    </b-button>
                                </b-col>
                            </b-row>
                            <div class="drag__cr">
                                <div class="setxaxis">
                                    <div class="lbl__txt">Columns</div>
                                    <div class="drop__xzone" :id="`dropx${index}`" @drop.prevent="dropStart($event, index)"
                                        @dragenter.prevent @dragover.prevent>
                                        <span v-for="(xdata, xindex) in sheetItem.getxdata" :key="xindex" class="xspandata"
                                            :data-type="xdata.type" :date-type="xdata.dateEnb"
                                            :id="`x${index}spandata` + xindex" @dragstart="draglabel" draggable="true"><b>{{
                                                xdata.name }}</b><font-awesome-icon icon="fa-regular fa-calendar"
                                                class="ms-3" v-if="xdata.dateEnb == 'datetime64[ns]'"
                                                @click="switchmonthyear(`sm${index}y${xindex}`)" /> <span class="svgagg"
                                                @click="openaggregatordrop(index, `col${xindex}_${index}_${xdata.name}`)"
                                                v-if="xdata.aggregation">f(x)</span> <font-awesome-icon
                                                icon="fa-solid fa-minus" class="svgclose"
                                                @click="clearxdata(xindex, index, xdata.name)" />
                                            <div v-if="xdata.aggregation" class="aggregationdropdown d-none"
                                                :id="`col${xindex}_${index}_${xdata.name}`">
                                                <b-form-select v-model="xdata.aggregation"
                                                    @change="updatedropdown($event, index, xdata.name)" size="sm">
                                                    <b-form-select-option :value="colagg"
                                                        v-for="(colagg, colaggindx) in aggregatorslist" :key="colaggindx"
                                                        v-if="xdata.type == 'numerical'">{{ colagg }}</b-form-select-option>
                                                    <b-form-select-option value="Select"
                                                        v-if="xdata.type == 'categories'">Select</b-form-select-option>
                                                    <b-form-select-option value="COUNT"
                                                        v-if="xdata.type == 'categories'">COUNT</b-form-select-option>
                                                </b-form-select>
                                            </div>
                                            <div :id="`sm${index}y${xindex}`" class="aggregationdropdown d-none">
                                                <b-form-select :value="xdata.datestatus"
                                                    @change="updatemonthyear($event, xdata)">
                                                    <b-form-select-option value="Select">Select</b-form-select-option>
                                                    <b-form-select-option value="Select">No Format</b-form-select-option>
                                                    <b-form-select-option v-for="(dteobj, dteidx) in datedropdwnlist"
                                                        :key="dteidx" :value="dteobj.format">{{ dteobj.name
                                                        }}</b-form-select-option>
                                                </b-form-select>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div class="setyaxis">
                                    <div class="lbl__txt">Row</div>
                                    <div class="drop__yzone" :id="`dropy${index}`" @drop.prevent="dropStart($event, index)"
                                        @dragenter.prevent @dragover.prevent>
                                        <span v-for="(ydata, yindex) in sheetItem.getydata" :key="yindex" class="yspandata"
                                            :data-type="ydata.type" :date-type="ydata.dateEnb"
                                            :id="`y${index}spandata` + yindex" @dragstart="draglabel" draggable="true"><b>{{
                                                ydata.name }}</b><font-awesome-icon icon="fa-regular fa-calendar"
                                                class="ms-3" v-if="ydata.dateEnb == 'datetime64[ns]'"
                                                @click="switchmonthyear(`sm${index}x${yindex}`)" /> <span class="svgagg"
                                                @click="openaggregatordrop(index, `row${yindex}_${index}_${ydata.name}`)"
                                                v-if="ydata.aggregation">f(x)</span> <font-awesome-icon
                                                icon="fa-solid fa-minus" class="svgclose"
                                                @click="clearydata(yindex, index, ydata.name)" />
                                            <div v-if="ydata.aggregation" class="aggregationdropdown d-none"
                                                :id="`row${yindex}_${index}_${ydata.name}`">
                                                <b-form-select v-model="ydata.aggregation"
                                                    @change="updatedropdown($event, index, ydata.name)" size="sm">
                                                    <b-form-select-option :value="rowagg"
                                                        v-for="(rowagg, rowaggindx) in aggregatorslist" :key="rowaggindx"
                                                        v-if="ydata.type == 'numerical'">{{ rowagg }}</b-form-select-option>
                                                    <b-form-select-option value="Select"
                                                        v-if="ydata.type == 'categories'">Select</b-form-select-option>
                                                    <b-form-select-option value="COUNT"
                                                        v-if="ydata.type == 'categories'">COUNT</b-form-select-option>
                                                </b-form-select>
                                            </div>
                                            <div :id="`sm${index}x${yindex}`" class="aggregationdropdown d-none">
                                                <b-form-select :value="ydata.datestatus"
                                                    @change="updatemonthyear($event, ydata)">
                                                    <b-form-select-option value="Select">Select</b-form-select-option>
                                                    <b-form-select-option value="Select">No Format</b-form-select-option>
                                                    <b-form-select-option v-for="(dteobj, dteidx) in datedropdwnlist"
                                                        :key="dteidx" :value="dteobj.format">{{ dteobj.name
                                                        }}</b-form-select-option>
                                                </b-form-select>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="sheet__list">
                                <div class="list_of_filternames">
                                    <span v-for="(fillist, inx) in sheetsData[index].filterobjlist" :key="inx">{{
                                        fillist.dimension }} <div v-on:mouseover="showvalinfo($event)"
                                            v-on:mouseleave="showvalinfo($event)" class="infoico">i</div>
                                        <div class="dropdownfilter_result d-none">
                                            <h6>Selected {{ fillist.dimension }} list:</h6>
                                            <ul v-if="fillist.value.length > 0">
                                                <li v-for="(filst, filind) in fillist.value" :key="filind">{{ filst
                                                }}</li>
                                            </ul>
                                            <ul v-if="fillist.category[0] == 'Range'">
                                                <li>{{ fillist.lowerBound }} - To - {{ fillist.upperBound }}</li>
                                            </ul>
                                        </div>
                                    </span>
                                </div>
                                <div class="sheet__item" @drop.prevent="dropgraphtypeStart($event, index)"
                                    @dragenter.prevent @dragover.prevent>
                                    <div class="table_scroll tbltdpad" v-if="sheetItem.shwtbl">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th v-for="(trth, thindx) in sheetItem.tableth" :key="thindx">{{
                                                        trth }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(trtd, tdindx) in sheetItem.tabledata" :key="tdindx">
                                                    <td v-for="(tddta, tdidx) in trtd" :key="tdidx">{{ tddta == null
                                                        ?
                                                        ' - ' : tddta }}</td>
                                                </tr>
                                                <tr class="grandtotaltable">
                                                    <td v-for="(tgrndtot, tgrndindx) in sheetItem.tablegrandtotal"
                                                        :key="tgrndindx">{{ tgrndtot }}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>

                                    <Pivot toolbar :beforetoolbarcreated="customizeToolbar" :customizeCell="customizeCellFunction"
                                        :report="sheetItem.newpivotreport" configuratorButton="false"
                                        v-if="sheetItem.shwpivottable">
                                    </Pivot>

                                    <div v-if="sheetItem.shwgraph || sheetItem.shwmultiplechartgraph"
                                        class="mt-3 pagination_custom chart_pagination">
                                        <span>Top</span> :<b-form-select v-model="sheetsData[index].graphtopdata"
                                            @change="fetchData">
                                            <b-form-select-option value="10">10</b-form-select-option>
                                            <b-form-select-option value="50">50</b-form-select-option>
                                            <b-form-select-option value="100">100</b-form-select-option>
                                            <b-form-select-option value="500">500</b-form-select-option>
                                            <b-form-select-option value="1000">1000</b-form-select-option>
                                        </b-form-select>
                                    </div>

                                    <v-chart class="chart" :option="sheetItem.chartoptions" @dataZoom="updateZoom"
                                        v-if="sheetItem.shwgraph" autoresize />

                                    <div v-if="sheetItem.shwtbl || sheetItem.shwpivottable"
                                        class="mt-3 mb-3 pagination_custom">
                                        <b-pagination v-model="sheetsData[index].tablepagination.currentpage"
                                            :total-rows="sheetsData[index].tablepagination.totalrows"
                                            :per-page="sheetsData[index].tablepagination.pages"
                                            @input="fetchData"></b-pagination>
                                        <b-form-select v-model="sheetsData[index].tablepagination.pages"
                                            @change="fetchData">
                                            <b-form-select-option value="50">50 / Page</b-form-select-option>
                                            <b-form-select-option value="100">100 / Page</b-form-select-option>
                                            <b-form-select-option value="500">500 / Page</b-form-select-option>
                                            <b-form-select-option value="1000">1000 / Page</b-form-select-option>
                                        </b-form-select>
                                        <b-form-input type="text" v-model="searchpagination"
                                            @keyup.enter="paginationsearch(searchpagination)"
                                            class="search__pagination"></b-form-input>
                                        <b-button @click="paginationsearch(searchpagination)" class="btn__go">Go</b-button>
                                    </div>

                                    <div v-for="(chartopt, chartidx) in sheetItem.multiplechartoptions" :key="chartidx"
                                        v-if="sheetItem.shwmultiplechartgraph">
                                        <v-chart class="chart" :option="chartopt" @dataZoom="updateZoom" autoresize />
                                    </div>

                                </div>
                            </div>
                        </b-tab>

                        <!-- New Tab Button (Using tabs-end slot) -->
                        <template #tabs-end>
                            <b-nav-item role="presentation" @click.prevent="newTab()" href="#"
                                class="addtb"><font-awesome-icon icon="fa-solid fa-notes-medical" /></b-nav-item>

                            <!-- <b-button class="logoutbtn" @click="logoutuser">Log out</b-button> -->
                        </template>

                        <!-- Render this if no tabs -->
                        <template #empty>
                            <div class="text-center text-muted">
                                There are no open Sheet<br>
                                Open a new sheet using the <b>+</b> button above.
                            </div>
                        </template>
                    </b-tabs>
                </b-card>

            </div>
        </div>

        <b-modal v-model="duplicatedatemodal" v-if="repeateddatedata != null" centered hide-footer hide-header
            no-close-on-backdrop>
            <div class="daterepeatermodal">
                <h6>Select date format for repeated date : </h6>
                <p class="repeateddateslistitm">
                    <span>{{ repeateddatedata.data.name }}</span>
                    <b-form-select v-model="repeateddatedata.data.datestatus"
                        @change="updaterepeateddata($event, repeateddatedata)">
                        <b-form-select-option value="Select">Select</b-form-select-option>
                        <b-form-select-option value="Select">No Format</b-form-select-option>
                        <b-form-select-option v-for="(dteobj, dteidx) in datedropdwnlist" :key="dteidx"
                            :value="dteobj.format">{{ dteobj.name }}</b-form-select-option>
                    </b-form-select>
                </p>
            </div>
        </b-modal>

    </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faDatabase, faChartLine, faGripVertical, faFile, faTable, faChartPie, faChartColumn, faRotateLeft, faRotateRight, faLayerGroup, faGear, faDownload, faCode, faExpand, faPalette, faListUl, faFilter, faMagnifyingGlass, faImage, faDeleteLeft, faTableColumns, faArrowRight, faGaugeHigh, faNotesMedical, faTableCellsLarge, faSquareRootVariable, faMinus, faHashtag, faCalculator, faAnglesDown, faTrashCan, faArrowUpWideShort, faArrowUpShortWide, faRotate, faXmark, faSort, faRetweet, faDollarSign } from '@fortawesome/free-solid-svg-icons'
import { faCircleXmark, faChartBar, faFloppyDisk, faNoteSticky, faFileLines, faPenToSquare, faCircleCheck, faCalendar, faCircleRight } from '@fortawesome/free-regular-svg-icons'
import Loader_vue from './Loader.vue'
import Loadertool_vue from './Loadertool.vue'
import gql from 'graphql-tag'
import HistogramSlider from 'vue-histogram-slider'

import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { PieChart, BarChart, LineChart, ScatterChart } from 'echarts/charts';
import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
    DataZoomComponent
} from 'echarts/components';
import VChart from 'vue-echarts';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

use([
    CanvasRenderer,
    PieChart,
    BarChart,
    LineChart,
    ScatterChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
    DataZoomComponent
]);

/* add icons to the library */
library.add(faDatabase, faChartLine, faGripVertical, faFile, faTable, faChartPie, faChartColumn, faRotateLeft, faRotateRight, faLayerGroup, faGear, faDownload, faCode, faExpand, faPalette, faListUl, faFilter, faMagnifyingGlass, faImage, faCircleXmark, faDeleteLeft, faTableColumns, faArrowRight, faChartBar, faGaugeHigh, faFloppyDisk, faNoteSticky, faNotesMedical, faTableCellsLarge, faSquareRootVariable, faMinus, faFileLines, faHashtag, faCalculator, faAnglesDown, faTrashCan, faArrowUpWideShort, faArrowUpShortWide, faPenToSquare, faRotate, faXmark, faSort, faCircleCheck, faRetweet, faCalendar, faDollarSign, faCircleRight)

export default {
    data() {
        return {
            showFilterModal: false,
            searchpagination: 1,
            repeateddatedata: null,
            duplicatedatemodal: false,
            updteidxnumber: null,
            appupsts: true,
            getallformulaobj: {},
            getformulafields: [],
            datedropdwnlist: null,
            showcurrencyformat: false,
            localtimests: 'dd/mm/yyyy',
            timpickersts: false,
            oneofsts: false,
            shwascdsc: false,
            datefiltertoggle: false,
            infolstdta: true,
            dateRange: {
                startDate: null,
                endDate: null,
            },
            shttbindx: 0,
            getactivatetab: 0,
            getcalId: null,
            getsheetdatasource: null,
            getdatasrcdescription: null,
            getallsourceslist: null,
            calfieldnme: null,
            calexplist: [],
            operatorlist: ["+", "-", "*", "%", "/"],
            numercallist: ["123"],
            formulasheetlisting: [],
            showcalculatedmodal: false,
            aggregatorslist: null,
            tabIndex: 0,
            filterminrange: 0,
            filtermaxrange: 0,
            filterselectedminrange: 0,
            filterselectedmaxrange: 0,
            filternumtab: false,
            rangesliderdata: [],
            opencolorpalate: false,
            getallsavedsheetsdata: null,
            currentActiveSheetIndex: 0,
            pivotdm: false,
            tabledm: true,
            piedm: false,
            barhdm: false,
            barvdm: false,
            linedm: false,
            scatterdm: false,
            treedm: false,
            ganttdm: false,
            kpidm: false,
            currentindex: null,
            loader_status: false,
            colorpicker: null,
            userId: null,
            sheetBlankData: {
                tablegrandtotal: [],
                graphtopdata: 50,
                tablepagination: {
                    currentpage: 1,
                    totalrows: 0,
                    pages: 100
                },
                getcurrencynumdata: [],
                currencyobj: [],
                sortobj: {},
                getcurrentshtdim: [],
                checkpivotaxisstatus: false,
                getstorexaxis: [],
                getstoreyaxis: [],
                currentdatasource: null,
                postagglist: [],
                expresslist: [],
                getpreviouscolor: null,
                prevclr: 0,
                renametext: 'sheet',
                showRenamestatus: false,
                clrrender: 0,
                dropfilterdata: {
                    name: null,
                    data: null,
                    selectfilterlist: []
                },
                fildtalod: false,
                filterlist: [],
                filterobjlist: [],
                sheetID: null,
                dimensionData: [],
                measuresData: [],
                getxdata: [],
                getydata: [],
                getallmeasuresdata: [],
                tableth: null,
                tabledata: null,
                shwgraph: false,
                shwtbl: false,
                shwpivottable: false,
                shwmultiplechartgraph: false,
                graphtype: 'table',
                chartoptions: {
                    tooltip: {
                        show: true
                    },
                    xAxis: {
                        type: "category",
                        data: [],
                        show: false
                    },
                    yAxis: {
                        type: "value",
                        data: [],
                        show: false
                    },
                    legend: {
                        data: []
                    },
                    dataZoom: [
                        {
                            type: 'slider',
                            height: 15,
                            show: true
                        },
                        {
                            type: 'inside'
                        }
                    ],
                    color: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
                    dataset: {
                        dimensions: [],
                        source: []
                    },
                    series: [
                        {
                            data: [],
                            type: 'bar',
                            tooltip: {},
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 3,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            }
                        }
                    ]
                },
                multiplechartoptions: [],
                newpivotreport: null,
                getxname: [],
                getyname: []

            },
            sheetsData: [],
        }
    },
    components: {
        Loader_vue,
        Loadertool_vue,
        HistogramSlider,
        VChart,
    },
    props: [
      'permissions'
    ],
    mounted() {
        this.userId = localStorage.getItem('userId');
        this.loadgetalldruidsources();

        // console.log(this.$hostname)

        this.$apollo
            .query({
                query: gql`
            query GetAggregators{
                measures{
                    data
                }
                }
            `,
            })
            .then((response_aggrigators) => {
                this.aggregatorslist = response_aggrigators.data.measures.data;
            })
            .catch((error) => {
                console.error('An error occurred:', error);
            });

        this.$apollo
            .query({
                query: gql`
                query DateDropDown{
                dateGranualities{
                    data{
                    name
                    format
                    }
                }
                }
            `,
            })
            .then((response_datedrpdwn) => {
                this.datedropdwnlist = response_datedrpdwn.data.dateGranualities.data;
            })
            .catch((error) => {
                console.error('An error occurred:', error);
            });

    },
    methods: {
        async loadgetalldruidsources(){
            let druidsourceresponse = null;
            const query3 = gql`
                query GetWorkspaceAllDruidSources($user_id:ID!,$filters:DruidDataSourceInput){
                    getAllDruidSources(userId:$user_id,
                    filters:$filters
                    ) {
                        id
                        datasource
                    }
                    }
                    `;
                try {
                    druidsourceresponse = await this.$axios.post(`${this.$hostname}/graphql/`, {
                        query: query3.loc.source.body,
                        variables: {
                            "user_id": this.userId,
                            "filters": {
                                "workspaceId": localStorage.getItem("activeworkspaceId")
                            }
                        }, // Pass the GraphQL query string
                    }, { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('token')}` } });
                    
                    
                    this.getallsourceslist = druidsourceresponse.data.data.getAllDruidSources;

                    if (druidsourceresponse) {
                        this.loadsheets();
                    }

                } catch (error) {
                    // Handle any errors
                    console.error(error.message);
                }
        },
        async loadsheets() {
            let response = null;

            const query3 = gql`
                query GetWorkspaceSheets($filters: SheetFilterInput!) {
                    getAllSheets(filters: $filters) {
                        id
                        name
                        datasource
                        dimensions
                        measures
                        filters
                        config
                        title
                    }
                    }
                `;
            try {
                response = await this.$axios.post(`${this.$hostname}/graphql/`, {
                    query: query3.loc.source.body,
                    variables: {
                        "filters": {
                            "workspaceId": localStorage.getItem("activeworkspaceId")
                        } // Pass the variable value here
                    }, // Pass the GraphQL query string
                }, { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('token')}` } });


                this.getallsavedsheetsdata = response.data.data.getAllSheets;
                if (this.getallsavedsheetsdata.length == 0) {
                    this.newTab();
                    this.getsheetdatasource = {
                        "data": {
                            "getDataSource": {
                                "name": "",
                                "description": null,
                                "numericalDimensions": [],
                                "categoricalDimensions": [],
                                "categoricalDimensionsInfo": [],
                                "numericalDimensionsInfo": []
                            }
                        }
                    };
                }
                else {
                    this.createsavedsheets(this.getallsavedsheetsdata);
                    // this.calculateduserdruidsources(this.getallsavedsheetsdata[0].datasource, true);
                }
            } catch (error) {
                // Handle any errors
                console.error(error.message);
            }
        },
        paginationsearch(inp) {
            this.sheetsData[this.currentActiveSheetIndex].tablepagination.currentpage = inp;
        },
        repeateddatecolumns(sts, xyidx) {
            if (sts == "x") {
                this.repeateddatedata = { data: this.sheetsData[this.currentActiveSheetIndex].getxdata[xyidx], index: xyidx, axis: sts };
            }
            if (sts == "y") {
                this.repeateddatedata = { data: this.sheetsData[this.currentActiveSheetIndex].getydata[xyidx], index: xyidx, axis: sts };
            }
        },
        opncalender() {
            this.showcalculatedmodal = true;
            this.appupsts = true;
            this.getformulafields = [];
            this.calfieldnme = null;
        },
        updatecalfield() {
            // console.log(this.getallformulaobj[Object.keys(this.getallformulaobj)[this.updteidxnumber]]);

            let arr = [];

            this.getformulafields.forEach((frmfld) => {
                if (!frmfld.operator) {
                    this.calexplist.push({
                        name: frmfld.getname,
                        field: frmfld.name,
                        type: "SUM"
                    });
                }
                arr.push(frmfld.getname);
            });

            this.formulasheetlisting[this.updteidxnumber] = {
                name: this.calfieldnme.replaceAll(' ', '_'),
                formula: arr.join(''),
                formulafields: this.calexplist
            };

            this.getallformulaobj[this.formulasheetlisting[this.updteidxnumber].name] = this.getformulafields;

            this.showcalculatedmodal = false;
            this.getformulafields = [];
            this.calfieldnme = null;
            this.calexplist = [];

            this.$apollo
                .mutate({
                    mutation: gql`
            mutation SaveCalculatedField($userId:ID,$Id:ID
            $datasource:String!,$config:JSONString!){
            updateDruidSource(userId:$userId,
                id:$Id,
            datasourceName:$datasource,
                config:$config
            ){
                druidSource{
                id
                name
                jsonConfig
                }
            }
            }
                `,
                    variables: {
                        "Id": this.getcalId, //This id is use for edit calculate field
                        "userId": this.userId,
                        "datasource": this.sheetsData[this.currentActiveSheetIndex].currentdatasource,
                        "config": JSON.stringify({ calculatefielddata: this.formulasheetlisting, storecalfild: this.getallformulaobj }),
                    },
                })
                .then((response) => {
                    // Handle the response
                    // console.log(response);
                    this.fetchData();
                    Swal.fire({
                        title: 'Update calculated field successfully!',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500
                    })
                })
                .catch((error) => {
                    // Handle any errors
                    console.error(error);
                    Swal.fire({
                        title: 'Something wrong!',
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 1500
                    })
                });
        },
        replacecalfild(obj, sugdta) {
            obj.name = sugdta;
            if (obj.number) {
                obj.getname = sugdta;
            }
            else {
                obj.getname = "SUM_" + sugdta;
            }
            obj.suggest = false;
            obj.mathmaticalsuggest = false;
        },
        NumbersOnly(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();;
            } else {
                return true;
            }
        },
        editcalculatedtexteditor(obj) {
            if (obj.number && obj.operator && !obj.mathmatical) {
                obj.input = !obj.input;
            }
            else if (obj.mathmatical) {
                obj.mathmaticalsuggest = !obj.mathmaticalsuggest;
            }
            else {
                obj.suggest = !obj.suggest;
            }
        },
        editcalculatedfield(dta, idx) {
            this.showcalculatedmodal = true;
            this.getformulafields = this.getallformulaobj[dta.name];
            this.calfieldnme = dta.name;
            this.appupsts = false;
            this.updteidxnumber = idx;
        },
        currencychange(sts) {
            this.sheetsData[this.currentActiveSheetIndex].currencyobj = [];
            if (this.sheetsData[this.currentActiveSheetIndex].getcurrencynumdata.length > 0) {
                this.sheetsData[this.currentActiveSheetIndex].getcurrencynumdata.forEach((getcurr) => {
                    if (getcurr.format != null && getcurr.format != "select") {
                        this.sheetsData[this.currentActiveSheetIndex].currencyobj.push({
                            field: getcurr.field,
                            format: getcurr.format
                        });
                    }
                    this.sheetsData[this.currentActiveSheetIndex].getxdata.forEach((xdta) => {
                        if (xdta.name == getcurr.field) {
                            xdta.currencyformat = getcurr.format;
                        }
                    });
                    this.sheetsData[this.currentActiveSheetIndex].getydata.forEach((ydta) => {
                        if (ydta.name == getcurr.field) {
                            ydta.currencyformat = getcurr.format;
                        }
                    });
                    this.sheetsData[this.currentActiveSheetIndex].getallmeasuresdata.forEach((mdta) => {
                        if (mdta.name == getcurr.field) {
                            mdta.currencyformat = getcurr.format;
                        }
                    });
                });
            }
            if (sts) {
                this.fetchData();
            }
        },
        checknumcurrency(sts) {

            if (sts) {
                this.showcurrencyformat = !this.showcurrencyformat;
            }

            if (this.sheetsData[this.currentActiveSheetIndex].getcurrencynumdata.length > 0) {
                let getcurrencylist = [];
                this.sheetsData[this.currentActiveSheetIndex].getcurrencynumdata.forEach((getcurr) => {
                    getcurrencylist.push(getcurr.field);
                });

                this.sheetsData[this.currentActiveSheetIndex].getxdata.forEach((xdt) => {
                    if (this.getsheetdatasource.data.getDataSource.numericalDimensions.includes(xdt.name)) {
                        if (!getcurrencylist.includes(xdt.name)) {
                            this.sheetsData[this.currentActiveSheetIndex].getcurrencynumdata.push({ field: xdt.name, format: null });
                        }
                    }
                });
                this.sheetsData[this.currentActiveSheetIndex].getydata.forEach((ydt) => {
                    if (this.getsheetdatasource.data.getDataSource.numericalDimensions.includes(ydt.name)) {
                        if (!getcurrencylist.includes(ydt.name)) {
                            this.sheetsData[this.currentActiveSheetIndex].getcurrencynumdata.push({ field: ydt.name, format: null });
                        }
                    }
                });
                if (this.sheetsData[this.currentActiveSheetIndex].graphtype == "pivot") {
                    this.sheetsData[this.currentActiveSheetIndex].getallmeasuresdata.forEach((mdt) => {
                        if (this.getsheetdatasource.data.getDataSource.numericalDimensions.includes(mdt.name)) {
                            if (!getcurrencylist.includes(mdt.name)) {
                                this.sheetsData[this.currentActiveSheetIndex].getcurrencynumdata.push({ field: mdt.name, format: null });
                            }
                        }
                    });
                }

            }
            else {
                this.sheetsData[this.currentActiveSheetIndex].getxdata.forEach((xdt) => {
                    if (this.getsheetdatasource.data.getDataSource.numericalDimensions.includes(xdt.name)) {
                        this.sheetsData[this.currentActiveSheetIndex].getcurrencynumdata.push({ field: xdt.name, format: null });
                    }
                });
                this.sheetsData[this.currentActiveSheetIndex].getydata.forEach((ydt) => {
                    if (this.getsheetdatasource.data.getDataSource.numericalDimensions.includes(ydt.name)) {
                        this.sheetsData[this.currentActiveSheetIndex].getcurrencynumdata.push({ field: ydt.name, format: null });
                    }
                });
                if (this.sheetsData[this.currentActiveSheetIndex].graphtype == "pivot") {
                    this.sheetsData[this.currentActiveSheetIndex].getallmeasuresdata.forEach((mdt) => {
                        if (this.getsheetdatasource.data.getDataSource.numericalDimensions.includes(mdt.name)) {
                            this.sheetsData[this.currentActiveSheetIndex].getcurrencynumdata.push({ field: mdt.name, format: null });
                        }
                    });
                }

            }
        },
        updatemonthyear(e, xymdta) {
            xymdta.datestatus = e;
            if (e != 'Select') {
                xymdta.datename = xymdta.name + e;
            }
            else {
                xymdta.datename = xymdta.name;
            }
            this.shwascdsc = false;
            if (this.sheetsData[this.currentActiveSheetIndex].sortobj.length > 0) {
                this.sheetsData[this.currentActiveSheetIndex].sortobj[0].field = xymdta.datename;
            }
            this.fetchData();
        },
        updaterepeateddata(e, dta) {
            if (dta.axis == "x") {
                this.sheetsData[this.currentActiveSheetIndex].getxdata[dta.index].datestatus = dta.data.datestatus;
                if (dta.data.datestatus != "Select") {
                    this.sheetsData[this.currentActiveSheetIndex].getxdata[dta.index].datename = dta.data.name + dta.data.datestatus;
                }
            }
            if (dta.axis == "y") {
                this.sheetsData[this.currentActiveSheetIndex].getydata[dta.index].datestatus = dta.data.datestatus;
                if (dta.data.datestatus != "Select") {
                    this.sheetsData[this.currentActiveSheetIndex].getydata[dta.index].datename = dta.data.name + dta.data.datestatus;
                }
            }
            this.fetchData();
            this.duplicatedatemodal = false;

            // console.log(this.sheetsData[this.currentActiveSheetIndex].getxdata);
            // console.log(this.sheetsData[this.currentActiveSheetIndex].getydata);
        },
        switchmonthyear(id) {
            // console.log(id);
            document.getElementById(id).classList.toggle("d-none");
            document.querySelectorAll(".aggregationdropdown").forEach((aggdrop) => {
                if (aggdrop.getAttribute("id") != id) {
                    aggdrop.classList.add("d-none");
                }
            });
        },
        convertdimandmeasures(dmdta, listidx) {
            // console.log(dmdta, listidx);
            this.loader_status = true;
            if (this.getsheetdatasource.data.getDataSource.numericalDimensions.includes(dmdta.name)) {
                this.getsheetdatasource.data.getDataSource.categoricalDimensions.push(dmdta.name);
                this.getsheetdatasource.data.getDataSource.categoricalDimensions.sort();
                this.getsheetdatasource.data.getDataSource.categoricalDimensionsInfo.splice(this.getsheetdatasource.data.getDataSource.categoricalDimensions.indexOf(dmdta.name), 0, dmdta);
                this.getsheetdatasource.data.getDataSource.numericalDimensions.splice(listidx, 1);
                this.getsheetdatasource.data.getDataSource.numericalDimensionsInfo.splice(listidx, 1);
            }
            else {
                this.getsheetdatasource.data.getDataSource.numericalDimensions.push(dmdta.name);
                this.getsheetdatasource.data.getDataSource.numericalDimensions.sort();
                this.getsheetdatasource.data.getDataSource.numericalDimensionsInfo.splice(this.getsheetdatasource.data.getDataSource.numericalDimensions.indexOf(dmdta.name), 0, dmdta);
                this.getsheetdatasource.data.getDataSource.categoricalDimensions.splice(listidx, 1);
                this.getsheetdatasource.data.getDataSource.categoricalDimensionsInfo.splice(listidx, 1);
            }
            this.savedimandmesdata();
        },
        createdatetimetoggle() {
            this.timpickersts = !this.timpickersts;
            this.dateRange.startDate = null;
            this.dateRange.endDate = null;
            if (this.timpickersts) {
                this.localtimests = 'dd/mm/yyyy hh:mm:ss';
            }
            else {
                this.localtimests = 'dd/mm/yyyy';
            }
        },
        getascdscorder(obj) {
            console.log(obj);
            this.sheetsData[this.currentActiveSheetIndex].getcurrentshtdim.forEach((currdim) => {
                if (currdim.name == obj.name) {
                    if (currdim.sort == null) {
                        currdim.sort = 'asc';
                        this.sheetsData[this.currentActiveSheetIndex].sortobj = [{
                            "field": obj.name,
                            "isAscending": true
                        }];
                    }
                    else if (currdim.sort == 'asc') {
                        currdim.sort = 'dsc';
                        this.sheetsData[this.currentActiveSheetIndex].sortobj = [{
                            "field": obj.name,
                            "isAscending": false
                        }];
                    }
                    else {
                        currdim.sort = 'asc';
                        this.sheetsData[this.currentActiveSheetIndex].sortobj = [{
                            "field": obj.name,
                            "isAscending": true
                        }];
                    }
                }
                else {
                    currdim.sort = null;
                }
            });

            this.fetchData();
        },
        ascendingorder(sts) {
            if (sts) {
                this.shwascdsc = !this.shwascdsc;
            }

            this.sheetsData[this.currentActiveSheetIndex].getcurrentshtdim = [];
            let nmelst = [];

            this.sheetsData[this.currentActiveSheetIndex].getxdata.forEach((xdta) => {
                this.sheetsData[this.currentActiveSheetIndex].getcurrentshtdim.push({ name: xdta.datename, sort: null, datests: xdta.datestatus });
            });

            this.sheetsData[this.currentActiveSheetIndex].getydata.forEach((ydta) => {
                this.sheetsData[this.currentActiveSheetIndex].getcurrentshtdim.push({ name: ydta.datename, sort: null, datests: ydta.datestatus });
            });

            if (this.sheetsData[this.currentActiveSheetIndex].graphtype == "pivot") {
                this.sheetsData[this.currentActiveSheetIndex].getallmeasuresdata.forEach((msdta) => {
                    this.sheetsData[this.currentActiveSheetIndex].getcurrentshtdim.push({ name: msdta.datename, sort: null, datests: msdta.datestatus });
                });
            }

            if (this.sheetsData[this.currentActiveSheetIndex].sortobj.length > 0) {
                this.sheetsData[this.currentActiveSheetIndex].getcurrentshtdim.forEach((activecs) => {
                    if (activecs.name == this.sheetsData[this.currentActiveSheetIndex].sortobj[0].field) {
                        if (this.sheetsData[this.currentActiveSheetIndex].sortobj[0].isAscending) {
                            activecs.sort = 'asc';
                        }
                    }
                })
            }

        },
        savedatasrcinfo() {
            this.infolstdta = !this.infolstdta;
            this.$apollo
                .mutate({
                    mutation: gql`
                    mutation UpdateDruidInfo($userId: ID, $Id: ID, $datasource: String!, $description: String!) {
                    updateDruidSource(
                        userId: $userId
                        id: $Id
                        datasourceName: $datasource
                        description: $description
                    ) {
                        druidSource {
                        id
                        name
                        description
                        }
                    }
                    }
                    `,
                    variables: {
                        "Id": this.getcalId,
                        "userId": this.userId,
                        "datasource": this.sheetsData[this.currentActiveSheetIndex].currentdatasource,
                        "description": this.getdatasrcdescription,
                    },
                })
                .then((response2) => {
                    console.log(response2);
                    this.loader_status = false;
                })
                .catch((error) => {
                    // Handle any errors
                    console.error(error);
                    Swal.fire({
                        title: 'Something wrong!',
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 1500
                    })
                });
        },
        savedimandmesdata() {
            this.infolstdta = !this.infolstdta;
            this.$apollo
                .mutate({
                    mutation: gql`
                mutation SaveColumnsInfo($userId: ID, $Id: ID, $datasource: String!, $columns_config: JSONString!) {
                    updateDruidSource(
                    userId: $userId
                    id: $Id
                    datasourceName: $datasource
                    columnsConfig: $columns_config
                    ) {
                    druidSource {
                        id
                        name
                    columnsConfig
                    }
                    }
                }
                `,
                    variables: {
                        "Id": this.getcalId,
                        "userId": this.userId,
                        "datasource": this.sheetsData[this.currentActiveSheetIndex].currentdatasource,
                        "columns_config": JSON.stringify(this.getsheetdatasource),
                    },
                })
                .then((response) => {
                    console.log(response);
                    this.loader_status = false;
                })
                .catch((error) => {
                    // Handle any errors
                    console.error(error);
                    Swal.fire({
                        title: 'Something wrong!',
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 1500
                    })
                });
        },
        toggleinfo(id) {
            document.querySelectorAll(".toggle_d_none").forEach((elm) => {
                if (elm.getAttribute("id") != id) {
                    elm.classList.remove("shwinfolst");
                }
            });
            document.getElementById(id).classList.toggle("shwinfolst");
        },
        resetsheet() {
            this.sheetsData[this.currentActiveSheetIndex].getxdata = [];
            this.sheetsData[this.currentActiveSheetIndex].getydata = [];
            this.sheetsData[this.currentActiveSheetIndex].filterlist = [];
            this.sheetsData[this.currentActiveSheetIndex].getallmeasuresdata = [];
            this.sheetsData[this.currentActiveSheetIndex].getxname = [];
            this.sheetsData[this.currentActiveSheetIndex].getyname = [];
            this.sheetsData[this.currentActiveSheetIndex].dimensionData = [];
            this.sheetsData[this.currentActiveSheetIndex].measuresData = [];
            this.sheetsData[this.currentActiveSheetIndex].filterobjlist = [];
            this.sheetsData[this.currentActiveSheetIndex].getstorexaxis = [];
            this.sheetsData[this.currentActiveSheetIndex].getstoreyaxis = [];
            this.sheetsData[this.currentActiveSheetIndex].graphtype = "table";
            this.sheetsData[this.currentActiveSheetIndex].checkpivotaxisstatus = false;
            this.sheetsData[this.currentActiveSheetIndex].getcurrentshtdim = [];
            this.sheetsData[this.currentActiveSheetIndex].sortobj = [];
        },
        async setactivatetab(tab) {
            let druidsourceresponse = null;
            const query3 = gql`
                query GetWorkspaceAllDruidSources($user_id:ID!,$filters:DruidDataSourceInput){
                    getAllDruidSources(userId:$user_id,
                    filters:$filters
                    ) {
                        id
                        datasource
                    }
                    }
                    `;
                try {
                    druidsourceresponse = await this.$axios.post(`${this.$hostname}/graphql/`, {
                        query: query3.loc.source.body,
                        variables: {
                            "user_id": this.userId,
                            "filters": {
                                "workspaceId": localStorage.getItem("activeworkspaceId")
                            }
                        }, // Pass the GraphQL query string
                    }, { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('token')}` } });
                    
                    
                    this.getallsourceslist = druidsourceresponse.data.data.getAllDruidSources;                   

                } catch (error) {
                    // Handle any errors
                    console.error(error.message);
                }

            this.getactivatetab = tab;
            localStorage.setItem("activatetab", this.getactivatetab)
        },
        updateZoom(e) {
            // See all available properties of the event
            console.log(e);
        },
        filterdashboardsheets(val, activegroupid) {
            let filter = val.toUpperCase();
            let listgroup = document.getElementById(activegroupid);
            let listgroupitem = listgroup.querySelectorAll(".list-group-item");
            let a, i, txtValue;

            for (i = 0; i < listgroupitem.length; i++) {
                a = listgroupitem[i].getElementsByTagName("span")[0];
                txtValue = a.textContent || a.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    listgroupitem[i].style.display = "";
                } else {
                    listgroupitem[i].style.display = "none";
                }
            }
        },
        async calculateduserdruidsources(src, sts) {
            this.formulasheetlisting = [];

            if (sts) {
                this.sheetsData[this.currentActiveSheetIndex].currentdatasource = src;
            }

            let calresponse = null;

            const query3 = gql`
            query UserDruidSources($user_id: ID!, $datasource: String!
                $filters: DruidDataSourceInput
                ) {
                getUserDruidSources(userId: $user_id, datasourceName: $datasource
                filters:$filters
                ) {
                    datasource
                    jsonConfig
                    columnsConfig
                    id
                }
                }
                `;
            try {
                calresponse = await this.$axios.post(`${this.$hostname}/graphql/`, {
                    query: query3.loc.source.body,
                    variables: {
                        "user_id": this.userId,
                        "datasource": this.sheetsData[this.currentActiveSheetIndex].currentdatasource,
                        "filters": {
                            "workspaceId": localStorage.getItem("activeworkspaceId")
                        }
                    }, // Pass the GraphQL query string
                }, { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('token')}` } });

                // console.log(calresponse)

                this.getcalId = calresponse.data.data.getUserDruidSources.id;
                if (calresponse.data.data.getUserDruidSources != null) {
                    if (calresponse.data.data.getUserDruidSources.datasource == this.sheetsData[this.currentActiveSheetIndex].currentdatasource) {
                        if (calresponse.data.data.getUserDruidSources.jsonConfig.calculatefielddata != undefined) {
                            this.formulasheetlisting = calresponse.data.data.getUserDruidSources.jsonConfig.calculatefielddata;
                        }
                        if (calresponse.data.data.getUserDruidSources.jsonConfig.storecalfild != undefined) {
                            this.getallformulaobj = calresponse.data.data.getUserDruidSources.jsonConfig.storecalfild;
                        }
                        this.getsheetdatasource = calresponse.data.data.getUserDruidSources.columnsConfig;
                        this.getdatasrcdescription = calresponse.data.data.getUserDruidSources.description;

                        this.fetchData();
                    }
                }


            } catch (error) {
                // Handle any errors
                console.error(error.message);
                // window.location.href="/";
                this.loader_status = false;
            }

        },
        datasourcechange(evt) {
            this.loader_status = true;
            this.sheetsData[this.currentActiveSheetIndex].currentdatasource = evt;

            this.resetsheet();
            this.calculateduserdruidsources();
            this.loader_status = false;

        },
        tabswitch(idxno) {
            this.loader_status = true;
            this.currentActiveSheetIndex = idxno;
            if (this.sheetsData[this.currentActiveSheetIndex].currentdatasource != null) {
                this.calculateduserdruidsources();
            }
            else {
                this.pivotdm = false;
                // this.tabledm = false;
                this.piedm = false;
                this.barhdm = false;
                this.barvdm = false;
                this.linedm = false;
                this.scatterdm = false;
                this.treedm = false;
                this.ganttdm = false;
                this.kpidm = false;

                this.loader_status = false;
                this.getsheetdatasource.data.getDataSource.categoricalDimensionsInfo = [];
                this.getsheetdatasource.data.getDataSource.numericalDimensionsInfo = [];
                this.formulasheetlisting = [];
            }

            let tabsdiv = document.querySelector(".scrltab .nav-tabs");
            if(this.isOverflown(tabsdiv)){
                document.querySelector(".card-footer").classList.add("botm");
            }
            else{
                document.querySelector(".card-footer").classList.remove("botm");
            }

            //.botm
        },
        clearcalculatedfield(dta, id) {
            this.formulasheetlisting.splice(id, 1);
            delete this.getallformulaobj[dta.name];
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation SaveCalculatedField($userId:ID,$Id:ID
                        $datasource:String!,$config:JSONString!){
                        updateDruidSource(userId:$userId,
                            id:$Id,
                        datasourceName:$datasource,
                            config:$config
                        ){
                            druidSource{
                            id
                            name
                            jsonConfig
                            }
                        }
                        }
                            `,
                    variables: {
                        "Id": this.getcalId,
                        "userId": this.userId,
                        "datasource": this.sheetsData[this.currentActiveSheetIndex].currentdatasource,
                        "config": JSON.stringify({ calculatefielddata: this.formulasheetlisting, storecalfild: this.getallformulaobj }),
                    },
                })
                .then((response) => {
                    // Handle the response
                    // console.log(response);
                    this.fetchData();
                    Swal.fire({
                        title: 'Delete calculated fields successfully!',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500
                    })
                })
                .catch((error) => {
                    // Handle any errors
                    console.error(error);
                    Swal.fire({
                        title: 'Something wrong!',
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 1500
                    })
                });
        },
        opennxtdrawer(id) {
            document.querySelectorAll(".heig0lst").forEach((elm) => {
                if (elm.getAttribute("id") != id) {
                    elm.classList.add("hei0");
                }
            });
            document.getElementById(id).classList.toggle("hei0");
            document.querySelectorAll(".toggle_d_none").forEach((elm) => {
                elm.classList.remove("shwinfolst");
            });

        },
        showvalinfo(e) {
            e.target.nextSibling.classList.toggle("d-none");
        },
        openaggregatordrop(ind, id) {
            document.querySelectorAll(".aggregationdropdown").forEach((aggdrop) => {
                if (aggdrop.getAttribute("id") != id) {
                    aggdrop.classList.add("d-none");
                }
                else {
                    aggdrop.classList.toggle("d-none");
                }
            });
        },
        openaggregatordrop2(e) {
            e.target.nextSibling.nextSibling.classList.toggle("d-none");
        },
        updatedropdown(e, sidx, xydtanme) {
            document.querySelectorAll(".aggregationdropdown").forEach((aggdrop) => {
                aggdrop.classList.add("d-none");
            });

            this.sheetsData[sidx].getxdata.forEach((xdt) => {
                if (xdt.name == xydtanme) {
                    xdt.aggregation = e;
                }
            });
            this.sheetsData[sidx].getydata.forEach((ydt) => {
                if (ydt.name == xydtanme) {
                    ydt.aggregation = e;
                }
            });
            this.sheetsData[sidx].getallmeasuresdata.forEach((mdt) => {
                if (mdt.name == xydtanme) {
                    mdt.aggregation = e;
                }
            });
            this.fetchData();
        },
        saverename() {
            this.sheetsData[this.currentActiveSheetIndex].showRenamestatus = false;
            this.saveTab(this.currentActiveSheetIndex);
        },
        renamesheet() {
            this.sheetsData[this.currentActiveSheetIndex].showRenamestatus = true;
        },
        cleardroppivotlist(indxno, gnme) {
            this.sheetsData[this.currentActiveSheetIndex].getallmeasuresdata.splice(indxno, 1);
            // if(this.sheetsData[indxno].getcurrentshtdim.length > 0){
            //     this.sheetsData[indxno].getcurrentshtdim.forEach((getcurrdim, gcindx)=>{
            //         if(getcurrdim.name == gnme){
            //             this.sheetsData[indxno].getcurrentshtdim.splice(gcindx, 1);
            //             if(this.sheetsData[this.currentActiveSheetIndex].sortobj.field == gnme){
            //                 this.sheetsData[this.currentActiveSheetIndex].sortobj = [];
            //             }
            //         }
            //     });
            // }
            if (this.sheetsData[indxno].sortobj.length > 0) {
                if (this.sheetsData[indxno].sortobj[0].field == xname) {
                    this.sheetsData[indxno].sortobj = [];
                }
            }
            if (this.sheetsData[indxno].getcurrencynumdata.length > 0) {
                this.sheetsData[indxno].getcurrencynumdata.forEach((getcurrdta, gcurrindx) => {
                    if (getcurrdta.field == gnme) {
                        this.sheetsData[indxno].getcurrencynumdata.splice(gcurrindx, 1);
                    }
                });
            }
            if (this.sheetsData[indxno].currencyobj.length > 0) {
                this.sheetsData[indxno].currencyobj.forEach((getcurrobj, gcurrobjindx) => {
                    if (getcurrobj.field == gnme) {
                        this.sheetsData[indxno].currencyobj.splice(gcurrobjindx, 1);
                    }
                });
            }

            this.fetchData();
        },
        clearfilterlist(indxno) {
            this.sheetsData[this.currentActiveSheetIndex].filterlist.splice(indxno, 1);
            this.sheetsData[this.currentActiveSheetIndex].filterobjlist.splice(indxno, 1);
            this.fetchData();
        },
        getrangevalue(e) {
            this.filterselectedminrange = e.from;
            this.filterselectedmaxrange = e.to;
        },
        addColors(clr) {
            this.sheetsData[this.currentActiveSheetIndex].clrrender++;
            this.sheetsData[this.currentActiveSheetIndex].chartoptions.color.unshift(clr);
            this.opencolorpalate = !this.opencolorpalate;
        },
        selectallfilter(filtnme) {
            if (this.sheetsData[this.currentActiveSheetIndex].dropfilterdata.selectfilterlist.length > 0) {
                this.sheetsData[this.currentActiveSheetIndex].dropfilterdata.selectfilterlist = [];
            }
            else {
                this.sheetsData[this.currentActiveSheetIndex].dropfilterdata.data.forEach((drpdta) => {
                    this.sheetsData[this.currentActiveSheetIndex].dropfilterdata.selectfilterlist.push(drpdta[filtnme]);
                })
            }
        },
        confirmfilter(selectfilterlist, currentindx) {
            this.createfilterobj(selectfilterlist, currentindx);
            this.showFilterModal = false;
            this.sheetsData[currentindx].dropfilterdata.selectfilterlist = [];
        },
        createfilterobj(filterdata, idx) {
            let categorytype = null;

            let startgdate, endgdate, startgmonth, endgmonth, startgyear, endgyear, startgtime, endgtime;

            if (this.datefiltertoggle) {
                startgdate = this.dateRange.startDate.getDate();
                endgdate = this.dateRange.endDate.getDate();
                startgmonth = this.dateRange.startDate.getMonth() + 1;
                endgmonth = this.dateRange.endDate.getMonth() + 1;
                startgyear = this.dateRange.startDate.getFullYear();
                endgyear = this.dateRange.endDate.getFullYear();
                startgtime = this.dateRange.startDate.toLocaleTimeString('it-IT');
                endgtime = this.dateRange.endDate.toLocaleTimeString('it-IT');

                if (startgdate < 10) {
                    startgdate = "0" + startgdate;
                }
                if (endgdate < 10) {
                    endgdate = "0" + endgdate;
                }
                if (startgmonth < 10) {
                    startgmonth = "0" + startgmonth;
                }
                if (endgmonth < 10) {
                    endgmonth = "0" + endgmonth;
                }
            }


            if (this.oneofsts) {
                categorytype = "OneOf";
            }
            else {
                categorytype = "Range";
            }
            let filterobj = null;

            if (this.datefiltertoggle) {
                if (this.timpickersts) {
                    filterobj = {
                        "dimension": this.sheetsData[idx].dropfilterdata.name,
                        "dataType": "numerical",
                        "lowerBound": startgyear + "-" + startgmonth + "-" + startgdate + " " + startgtime,
                        "upperBound": endgyear + "-" + endgmonth + "-" + endgdate + " " + endgtime,
                        "category": ["Range"],
                        "value": filterdata
                    };
                }
                else {
                    filterobj = {
                        "dimension": this.sheetsData[idx].dropfilterdata.name,
                        "dataType": "numerical",
                        "lowerBound": startgyear + "-" + startgmonth + "-" + startgdate,
                        "upperBound": endgyear + "-" + endgmonth + "-" + endgdate,
                        "category": ["Range"],
                        "value": filterdata
                    };
                }
            }
            else {
                filterobj = {
                    "dimension": this.sheetsData[idx].dropfilterdata.name,
                    "dataType": "numerical",
                    "lowerBound": this.filterselectedminrange,
                    "upperBound": this.filterselectedmaxrange,
                    "category": [`${categorytype}`],
                    "value": filterdata
                };
            }

            this.sheetsData[idx].filterobjlist.forEach((filele, filidx) => {
                if (filele.dimension == this.sheetsData[idx].dropfilterdata.name) {
                    this.sheetsData[idx].filterobjlist.splice(filidx, 1);
                    if (this.datefiltertoggle) {
                        if (this.timpickersts) {
                            filterobj = {
                                "dimension": this.sheetsData[idx].dropfilterdata.name,
                                "dataType": "numerical",
                                "lowerBound": startgyear + "-" + startgmonth + "-" + startgdate + " " + startgtime,
                                "upperBound": endgyear + "-" + endgmonth + "-" + endgdate + " " + endgtime,
                                "category": ["Range"],
                                "value": filterdata
                            };
                        }
                        else {
                            filterobj = {
                                "dimension": this.sheetsData[idx].dropfilterdata.name,
                                "dataType": "numerical",
                                "lowerBound": startgyear + "-" + startgmonth + "-" + startgdate,
                                "upperBound": endgyear + "-" + endgmonth + "-" + endgdate,
                                "category": ["Range"],
                                "value": filterdata
                            };
                        }
                    }
                    else {
                        filterobj = {
                            "dimension": this.sheetsData[idx].dropfilterdata.name,
                            "dataType": "numerical",
                            "lowerBound": this.filterselectedminrange,
                            "upperBound": this.filterselectedmaxrange,
                            "category": [`${categorytype}`],
                            "value": filterdata
                        };
                    }
                }
            })

            this.sheetsData[idx].filterobjlist.push(filterobj);
            this.fetchData();

        },
        reverseSelection(dta, seldta, filtnme) {
            let seldtalist = [];
            let dtalist = [];
            dta.forEach((dtaelm) => {
                dtalist.push(dtaelm[filtnme]);
            });
            seldta.forEach((elm) => {
                seldtalist.push(elm);
            });

            this.sheetsData[this.currentActiveSheetIndex].dropfilterdata.selectfilterlist = [];

            dtalist.forEach((dtlst) => {
                if (!seldtalist.includes(dtlst)) {
                    this.sheetsData[this.currentActiveSheetIndex].dropfilterdata.selectfilterlist.push(dtlst);
                }
            });
        },
        createsavedsheets(savedsheetsdata) {
            if (savedsheetsdata.length != 0) {
                let sheetobj = null;
                savedsheetsdata.forEach((element) => {
                    sheetobj = {
                        sheetBlankData: {
                            tablegrandtotal: [],
                            graphtopdata: 50,
                            tablepagination: {
                                currentpage: 1,
                                totalrows: 0,
                                pages: 100
                            },
                            getcurrencynumdata: element.config.getcurrencynumdata,
                            currencyobj: element.config.currencyobj,
                            sortobj: element.config.sortobj,
                            getcurrentshtdim: element.config.getcurrentshtdim,
                            checkpivotaxisstatus: element.config.checkpivotaxisstatus,
                            getstorexaxis: element.config.getstorexaxis,
                            getstoreyaxis: element.config.getstoreyaxis,
                            currentdatasource: element.datasource,
                            postagglist: element.config.postagdata,
                            expresslist: element.config.expresdata,
                            getpreviouscolor: null,
                            prevclr: 0,
                            renametext: element.title,
                            showRenamestatus: false,
                            clrrender: 0,
                            dropfilterdata: {
                                name: null,
                                data: null,
                                selectfilterlist: []
                            },
                            fildtalod: false,
                            filterlist: element.config.filterlist,
                            filterobjlist: element.filters,
                            sheetID: element.id,
                            dimensionData: element.dimensions,
                            measuresData: [],
                            getxdata: element.config.getxdata,
                            getydata: element.config.getydata,
                            getallmeasuresdata: element.config.getallmeasuresdata,
                            tableth: null,
                            tabledata: null,
                            shwgraph: false,
                            shwtbl: false,
                            shwpivottable: false,
                            shwmultiplechartgraph: false,
                            graphtype: element.config.graphtype,
                            chartoptions: {
                                tooltip: {
                                    show: true
                                },
                                xAxis: {
                                    type: "category",
                                    data: [],
                                    show: false
                                },
                                yAxis: {
                                    type: "value",
                                    data: [],
                                    show: false
                                },
                                legend: {
                                    data: []
                                },
                                dataZoom: [
                                    {
                                        type: 'slider',
                                        height: 15,
                                        show: true
                                    },
                                    {
                                        type: 'inside'
                                    }
                                ],
                                color: element.config.color,
                                dataset: {
                                    dimensions: [],
                                    source: []
                                },
                                series: [
                                    {
                                        data: [],
                                        type: element.config.graphtype,
                                        tooltip: {},
                                        emphasis: {
                                            itemStyle: {
                                                shadowBlur: 3,
                                                shadowOffsetX: 0,
                                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                                            }
                                        }
                                    }
                                ]
                            },
                            multiplechartoptions: [],
                            newpivotreport: null,
                            getxname: [],
                            getyname: []

                        },

                    }

                    this.sheetsData.push(JSON.parse(JSON.stringify(sheetobj.sheetBlankData)));
                    this.currencychange(false);
                    this.calculateduserdruidsources();
                });
                // this.fetchData();
            }

        },
        saveTab(ind) {
            let datespec = [];
            let cofobj = {
                getxdata: this.sheetsData[ind].getxdata,
                getydata: this.sheetsData[ind].getydata,
                graphtype: this.sheetsData[ind].graphtype,
                color: this.sheetsData[ind].chartoptions.color,
                filterlist: this.sheetsData[ind].filterlist,
                getallmeasuresdata: this.sheetsData[ind].getallmeasuresdata,
                postagglist: this.sheetsData[ind].postagglist,
                expresslist: this.sheetsData[ind].expresslist,
                sortobj: this.sheetsData[ind].sortobj,
                getcurrentshtdim: this.sheetsData[ind].getcurrentshtdim,
                getcurrencynumdata: this.sheetsData[ind].getcurrencynumdata,
                currencyobj: this.sheetsData[ind].currencyobj
            }

            this.sheetsData[ind].getxdata.forEach((xdta) => {
                datespec.push(xdta);
            });
            this.sheetsData[ind].getydata.forEach((ydta) => {
                datespec.push(ydta);
            });
            this.sheetsData[ind].getallmeasuresdata.forEach((mdta) => {
                datespec.push(mdta);
            });

            if (this.sheetsData[ind].renametext == 'sheet') {
                this.sheetsData[ind].renametext = 'sheet-' + ind;
            }
            if (this.sheetsData[ind].sheetID != null) {
                this.$apollo
                    .mutate({
                        mutation: gql`
                            mutation CreateSheet($sheet_input:SheetInput){
                            createSheet(
                            sheetData:$sheet_input
                            ){
                                sheet{
                                datasource
                                id
                                name
                                dimensions
                                dimensionsSpec
                                measures
                                filters
                                config
                                }
                            }
                            }
                            `,
                        variables: {
                            "sheet_input": {
                                "workspaceId": localStorage.getItem("activeworkspaceId"),
                                "datasource": `${this.sheetsData[ind].currentdatasource}`,
                                "dimensions": this.sheetsData[ind].dimensionData,
                                "measures": [{ "name": "metric", "field": `${this.sheetsData[ind].measuresData}`, "type": "SUM" }],
                                "segment": "",
                                "dimensionsSpec": JSON.stringify(datespec),
                                "config": JSON.stringify(cofobj),
                                "interval": "",
                                "id": `${this.sheetsData[ind].sheetID}`,
                                "ownerId": localStorage.getItem('userId'),
                                "sheetName": this.sheetsData[ind].renametext,
                                "filters": this.sheetsData[ind].filterobjlist
                            }
                        },
                    })
                    .then((response) => {
                        // Handle the response
                        // console.log(response);
                        this.$emit('dashboardenable', 2);

                        if (response) {
                            let response3 = null;
                            const query3 = async () => { gql`
                                query GetWorkspaceSheets($filters: SheetFilterInput!) {
                                    getAllSheets(filters: $filters) {
                                        id
                                        name
                                        datasource
                                        dimensions
                                        measures
                                        filters
                                        config
                                        title
                                    }
                                    }
                                `;
                            try {
                                response3 = await this.$axios.post(`${this.$hostname}/graphql/`, {
                                    query: query3.loc.source.body,
                                    variables: {
                                        "filters": {
                                            "workspaceId": localStorage.getItem("activeworkspaceId")
                                        } // Pass the variable value here
                                    }, // Pass the GraphQL query string
                                }, { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('token')}` } });
                                // Handle the GraphQL response data

                                this.getallsavedsheetsdata = response3.data.data.getAllSheets;
                                this.sheetsData[ind].sheetID = response3.data.data.getAllSheets[ind].id;
                                
                            } catch (error) {
                                // Handle any errors
                                console.error(error.message);
                            }
                        }
                    }
                    Swal.fire({
                        title: 'Update sheet successfully!',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500
                    })
                })
                .catch((error) => {
                    // Handle any errors
                    console.error(error);
                    Swal.fire({
                        title: 'Something wrong!',
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 1500
                    })
                });
            }
            else {
                this.$apollo
                    .mutate({
                        mutation: gql`
                            mutation CreateSheet($sheet_input:SheetInput){
                            createSheet(
                            sheetData:$sheet_input
                            ){
                                sheet{
                                datasource
                                id
                                name
                                dimensions
                                dimensionsSpec
                                measures
                                filters
                                config
                                }
                            }
                            }
                            `,
                        variables: {
                            "sheet_input": {
                                "workspaceId": localStorage.getItem("activeworkspaceId"),
                                "datasource": this.sheetsData[ind].currentdatasource,
                                "dimensions": this.sheetsData[ind].dimensionData,
                                "measures": [{ "name": "metric", "field": `${this.sheetsData[ind].measuresData}`, "type": "SUM" }],
                                "segment": "",
                                "dimensionsSpec": JSON.stringify(datespec),
                                "config": JSON.stringify(cofobj),
                                "interval": "",
                                "ownerId": localStorage.getItem('userId'),
                                "sheetName": this.sheetsData[ind].renametext,
                                "filters": this.sheetsData[ind].filterobjlist
                            }
                        },
                    })
                    .then((response) => {
                        // Handle the response
                        // console.log(response);
                        if (response) {
                            this.$emit('dashboardenable', 2);
                        }

                            let response3 = null;
                            const query3 = async () => { gql`
                                query GetWorkspaceSheets($filters: SheetFilterInput!) {
                                    getAllSheets(filters: $filters) {
                                        id
                                        name
                                        datasource
                                        dimensions
                                        measures
                                        filters
                                        config
                                        title
                                    }
                                    }
                                `;
                            try {
                                response3 = await this.$axios.post(`${this.$hostname}/graphql/`, {
                                    query: query3.loc.source.body,
                                    variables: {
                                        "filters": {
                                            "workspaceId": localStorage.getItem("activeworkspaceId")
                                        } // Pass the variable value here
                                    }, // Pass the GraphQL query string
                                }, { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('token')}` } });
                                
                                // Handle the GraphQL response data
                                this.getallsavedsheetsdata = response3.data.data.getAllSheets;
                                this.sheetsData[ind].sheetID = response3.data.data.getAllSheets[ind].id;

                            } catch (error) {
                                // Handle any errors
                                console.error(error.message);
                            }
                        }
                        Swal.fire({
                            title: 'Sheet saved successfully!',
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    })
                    .catch((error) => {
                        // Handle any errors
                        console.error(error);
                        Swal.fire({
                            title: 'Something wrong!',
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    });
            }

            // console.log(this.savedsheetsdata)

        },
        changegraphtype(gtype, graphtyp) {
            this.sheetsData[this.currentActiveSheetIndex].getallmeasuresdata = [];
            this.sheetsData[this.currentActiveSheetIndex].shwtbl = false;
            this.sheetsData[this.currentActiveSheetIndex].shwgraph = false;
            this.sheetsData[this.currentActiveSheetIndex].shwpivottable = false;
            this.sheetsData[this.currentActiveSheetIndex].graphtype = gtype;
            this.sheetsData[this.currentActiveSheetIndex].chartoptions.series[0].type = gtype;
            if (gtype == "pivot") {
                this.sheetsData[this.currentActiveSheetIndex].shwpivottable = true;
                this.checkxypivotaxis();
            }
            else {
                if (this.sheetsData[this.currentActiveSheetIndex].checkpivotaxisstatus) {
                    this.sheetsData[this.currentActiveSheetIndex].getxdata = this.sheetsData[this.currentActiveSheetIndex].getstorexaxis;
                    this.sheetsData[this.currentActiveSheetIndex].getydata = this.sheetsData[this.currentActiveSheetIndex].getstoreyaxis;
                    this.sheetsData[this.currentActiveSheetIndex].checkpivotaxisstatus = false;
                }
            }

            let getxdata = this.sheetsData[this.currentActiveSheetIndex].getxdata;
            let getydata = this.sheetsData[this.currentActiveSheetIndex].getydata;

            if (graphtyp == "vertical") {
                this.sheetsData[this.currentActiveSheetIndex].getxdata = [];
                this.sheetsData[this.currentActiveSheetIndex].getydata = [];
                this.sheetsData[this.currentActiveSheetIndex].getxdata = getydata;
                this.sheetsData[this.currentActiveSheetIndex].getydata = getxdata;
            }
            if (graphtyp == "horizontal") {
                this.sheetsData[this.currentActiveSheetIndex].getxdata = [];
                this.sheetsData[this.currentActiveSheetIndex].getydata = [];
                this.sheetsData[this.currentActiveSheetIndex].getxdata = getydata;
                this.sheetsData[this.currentActiveSheetIndex].getydata = getxdata;
            }
            this.fetchData();

        },
        checkxypivotaxis() {
            let newgetxdata = [];
            let newgetydata = [];
            let formulist = [];

            this.sheetsData[this.currentActiveSheetIndex].getstorexaxis = this.sheetsData[this.currentActiveSheetIndex].getxdata;
            this.sheetsData[this.currentActiveSheetIndex].getstoreyaxis = this.sheetsData[this.currentActiveSheetIndex].getydata;

            this.sheetsData[this.currentActiveSheetIndex].checkpivotaxisstatus = true;

            this.formulasheetlisting.forEach((frmlist) => {
                formulist.push(frmlist.name);
            });
            this.sheetsData[this.currentActiveSheetIndex].getxdata.forEach((xele) => {
                if (this.getsheetdatasource.data.getDataSource.numericalDimensions.includes(xele.name)) {
                    let objnumtyp = { "name": xele.name, "type": "numerical", aggregation: "SUM", datestatus: null, dateEnb: "disabled", currencyformat: null, datename: xele.name };
                    this.sheetsData[this.currentActiveSheetIndex].getallmeasuresdata.push(objnumtyp);
                }
                else if (formulist.includes(xele.name)) {
                    let objnumtyp = { "name": xele.name, "type": "numerical", aggregation: "SUM", datestatus: null, dateEnb: "disabled", currencyformat: null, datename: xele.name };
                    this.sheetsData[this.currentActiveSheetIndex].getallmeasuresdata.push(objnumtyp);
                }
                else {
                    newgetxdata.push(xele);
                }

            });
            this.sheetsData[this.currentActiveSheetIndex].getydata.forEach((yele) => {
                if (this.getsheetdatasource.data.getDataSource.numericalDimensions.includes(yele.name)) {
                    let objnumtyp = { "name": yele.name, "type": "numerical", aggregation: "SUM", datestatus: null, dateEnb: "disabled", currencyformat: null, datename: yele.name };
                    this.sheetsData[this.currentActiveSheetIndex].getallmeasuresdata.push(objnumtyp);
                }
                else if (formulist.includes(yele.name)) {
                    let objnumtyp = { "name": yele.name, "type": "numerical", aggregation: "SUM", datestatus: null, dateEnb: "disabled", currencyformat: null, datename: yele.name };
                    this.sheetsData[this.currentActiveSheetIndex].getallmeasuresdata.push(objnumtyp);
                }
                else {
                    newgetydata.push(yele);
                }
            });

            this.sheetsData[this.currentActiveSheetIndex].getxdata = newgetxdata;
            this.sheetsData[this.currentActiveSheetIndex].getydata = newgetydata;
        },
        storepivotvalues() {
            this.sheetsData[this.currentActiveSheetIndex].getstorexaxis = this.sheetsData[this.currentActiveSheetIndex].getxdata;
            this.sheetsData[this.currentActiveSheetIndex].getstoreyaxis = this.sheetsData[this.currentActiveSheetIndex].getydata;

            this.sheetsData[this.currentActiveSheetIndex].getstoreyaxis = this.sheetsData[this.currentActiveSheetIndex].getstoreyaxis.concat(this.sheetsData[this.currentActiveSheetIndex].getallmeasuresdata);
            this.sheetsData[this.currentActiveSheetIndex].checkpivotaxisstatus = true;
        },
        draggraphtypestart(e) {
            e.dataTransfer.setData("text", e.target.getAttribute("data-id"));
        },
        dropgraphtypeStart(e, indno) {
            e.preventDefault();
            var droptypedata = e.dataTransfer.getData("text");
            if (droptypedata == "hbar") {
                this.sheetsData[indno].graphtype = "bar";
            }
            else {
                this.sheetsData[indno].graphtype = droptypedata;
            }



        },
        droppivotvalue(e) {
            e.preventDefault();
            var droppivotvaluename = e.dataTransfer.getData("text");
            if (this.sheetsData[this.currentActiveSheetIndex].getallmeasuresdata.length > 0) {
                let mesnme = [];
                this.sheetsData[this.currentActiveSheetIndex].getallmeasuresdata.forEach((getmesdta) => {
                    mesnme.push(getmesdta.name);
                });
                if (!mesnme.includes(droppivotvaluename)) {
                    this.sheetsData[this.currentActiveSheetIndex].getallmeasuresdata.push({ name: droppivotvaluename, type: "numerical", aggregation: "SUM", datestatus: null, dateEnb: "disabled", currencyformat: null, datename: droppivotvaluename });
                }
            }
            else {
                this.sheetsData[this.currentActiveSheetIndex].getallmeasuresdata.push({ name: droppivotvaluename, type: "numerical", aggregation: "SUM", datestatus: null, dateEnb: "disabled", currencyformat: null, datename: droppivotvaluename });
            }
            this.storepivotvalues();

            this.fetchData();
        },
        dropfilter(e) {
            e.preventDefault();
            var dropfiltername = e.dataTransfer.getData("text");
            this.filtermodal(dropfiltername);

        },
        dropcalculated(e) {
            e.preventDefault();
            let dropcalfildname = e.dataTransfer.getData("text");
            let dropoprator = e.dataTransfer.getData("data-field");

            console.log(dropoprator)
            if (dropoprator == "operator" || dropoprator == "number") {
                if (dropoprator == "number") {
                    this.getformulafields.push({ name: dropcalfildname, operator: true, number: true, input: true, suggest: false, mathmatical: false, mathmaticalsuggest: false, getname: dropcalfildname });
                }
                else if (dropcalfildname == "+" || dropcalfildname == "-" || dropcalfildname == "*" || dropcalfildname == "/" || dropcalfildname == "%") {
                    this.getformulafields.push({ name: dropcalfildname, operator: true, number: false, input: false, suggest: false, mathmatical: true, mathmaticalsuggest: false, getname: dropcalfildname });
                }
                else {
                    this.getformulafields.push({ name: dropcalfildname, operator: true, number: false, input: false, suggest: false, mathmatical: false, mathmaticalsuggest: false, getname: dropcalfildname });
                }
            }
            else {
                this.getformulafields.push({ name: dropcalfildname, operator: false, number: false, input: false, suggest: false, mathmatical: false, mathmaticalsuggest: false, getname: "SUM_" + dropcalfildname });
            }
        },
        createcalfield() {
            if (this.calfieldnme != null) {
                let arr = [];
                this.getformulafields.forEach((frmfld) => {
                    if (!frmfld.operator) {
                        this.calexplist.push({
                            name: frmfld.getname,
                            field: frmfld.name,
                            type: "SUM"
                        });
                    }
                    arr.push(frmfld.getname);
                });

                this.formulasheetlisting.push({
                    name: "_" + this.calfieldnme.replaceAll(' ', '_'),
                    formula: arr.join(''),
                    formulafields: this.calexplist
                });

                this.getallformulaobj[this.formulasheetlisting[this.formulasheetlisting.length - 1].name] = this.getformulafields;

                this.showcalculatedmodal = false;
                this.getformulafields = [];
                this.calfieldnme = null;
                this.calexplist = [];

                if (this.formulasheetlisting.length > 0) {
                    this.$apollo
                        .mutate({
                            mutation: gql`
                        mutation SaveCalculatedField($userId:ID,$Id:ID
                        $datasource:String!,$config:JSONString!){
                        updateDruidSource(userId:$userId,
                            id:$Id,
                        datasourceName:$datasource,
                            config:$config
                        ){
                            druidSource{
                            id
                            name
                            jsonConfig
                            }
                        }
                        }
                            `,
                            variables: {
                                "Id": this.getcalId, //This id is use for edit calculate field
                                "userId": this.userId,
                                "datasource": this.sheetsData[this.currentActiveSheetIndex].currentdatasource,
                                "config": JSON.stringify({ calculatefielddata: this.formulasheetlisting, storecalfild: this.getallformulaobj }),
                            },
                        })
                        .then((response) => {
                            // Handle the response
                            console.log(response);
                            Swal.fire({
                                title: 'Add calculated field successfully!',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 1500
                            })
                        })
                        .catch((error) => {
                            // Handle any errors
                            console.error(error);
                            Swal.fire({
                                title: 'Something wrong!',
                                icon: 'error',
                                showConfirmButton: false,
                                timer: 1500
                            })
                        });
                }


            }
            else {
                Swal.fire({
                    title: 'Type name of calculated field',
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        },
        filtermodal(drpname) {
            // console.log(drpname, this.sheetsData[this.currentActiveSheetIndex].filterobjlist);
            this.datefiltertoggle = false;
            this.oneofsts = true;

            if (this.sheetsData[this.currentActiveSheetIndex].filterobjlist.length > 0) {
                this.sheetsData[this.currentActiveSheetIndex].filterobjlist.forEach((fildtaobj) => {
                    if (fildtaobj.dimension == drpname) {
                        this.sheetsData[this.currentActiveSheetIndex].dropfilterdata.selectfilterlist = fildtaobj.value;
                        this.dateRange.startDate = fildtaobj.lowerBound;
                        this.dateRange.endDate = fildtaobj.upperBound;
                    }
                })
            }


            this.sheetsData[this.currentActiveSheetIndex].fildtalod = true;
            if (!this.sheetsData[this.currentActiveSheetIndex].filterlist.includes(drpname)) {
                this.sheetsData[this.currentActiveSheetIndex].filterlist.push(drpname);
            }

            this.showFilterModal = true;

            this.getsheetdatasource.data.getDataSource.categoricalDimensionsInfo.forEach((catelm) => {
                if (catelm.name == drpname) {
                    if (catelm.type == "datetime64[ns]") {
                        this.datefiltertoggle = true;
                        this.oneofsts = false;
                    }
                }
            })

            this.getsheetdatasource.data.getDataSource.numericalDimensionsInfo.forEach((numelm) => {
                if (numelm.name == drpname) {
                    if (numelm.type == "datetime64[ns]") {
                        this.datefiltertoggle = true;
                        this.oneofsts = false;
                        this.filternumtab = false;
                    }
                }
            })

            if (this.getsheetdatasource.data.getDataSource.numericalDimensions.includes(drpname)) {
                this.filternumtab = true;
                this.oneofsts = false;
            }
            else {
                this.filternumtab = false;
            }

            this.sheetsData[this.currentActiveSheetIndex].dropfilterdata.name = drpname;

            if (this.oneofsts) {
                this.$apollo
                    .query({
                        query: gql`
                query ShowFilterValues($input:DataSourceInput!){
                    getFilterValues(input:$input)
                }
                `,
                        variables: {
                            "input": {
                                "datasource": this.sheetsData[this.currentActiveSheetIndex].currentdatasource,
                                "dimensions": drpname
                            }
                        },
                    })
                    .then((responsefilterlist) => {
                        this.sheetsData[this.currentActiveSheetIndex].dropfilterdata.data = responsefilterlist.data.getFilterValues;
                        this.sheetsData[this.currentActiveSheetIndex].fildtalod = false;
                    })
                    .catch((error) => {
                        console.error('An error occurred:', error);
                    });

            }
            else if (this.filternumtab) {
                this.$apollo
                    .query({
                        query: gql`
                    query MeasureLimits($datasource: String!, $measure: String!) {
                    getMeasuresLimits(datasource: $datasource, measure: $measure) {
                        minValue
                        maxValue
                    }
                    }
                `,
                        variables: {
                            "datasource": this.sheetsData[this.currentActiveSheetIndex].currentdatasource,
                            "measure": drpname
                        },
                    })
                    .then((responsefilterlist) => {
                        this.filterminrange = responsefilterlist.data.getMeasuresLimits.minValue;
                        this.filtermaxrange = responsefilterlist.data.getMeasuresLimits.maxValue;
                        this.sheetsData[this.currentActiveSheetIndex].fildtalod = false;
                    })
                    .catch((error) => {
                        console.error('An error occurred:', error);
                    });
            }
            else {
                this.sheetsData[this.currentActiveSheetIndex].fildtalod = false;
            }
        },
        async closeTab(x, sheetid) {

            let responsedashboard = null;
            const query3 = gql`
            query WorkspaceWiseDashboards($filters:WorkspaceFiltersInput){
                getDashboardsWorkspaceWise(filters:$filters) {
                    id
                    name
                    description
                    department {
                    id
                    name
                    }
                    dashboards {
                    id
                    name
                    config
                    canvasConfig
                    dashboardSheets {
                        id
                        filters
                        sheet {
                        id
                        name
                        dimensions
                        dimensionsSpec
                        datasource
                        measures
                        filters
                        config
                        }
                    }
                    }
                }
                }
                `;
            try {
                responsedashboard = await this.$axios.post(`${this.$hostname}/graphql/`, {
                    query: query3.loc.source.body,
                    variables: {
                        "filters": {
                            "id": localStorage.getItem("activeworkspaceId")
                        } // Pass the variable value here
                    }, // Pass the GraphQL query string
                }, { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('token')}` } });

                    let shtctr = 0;

                    responsedashboard.data.data.getDashboardsWorkspaceWise[0].dashboards.forEach((dash) => {
                        dash.config[0].sheetsdata.forEach((confshtdta) => {
                            if (confshtdta.id == sheetid) {
                                shtctr++;
                            }
                        })
                    })

                    if (shtctr == 0) {
                        Swal.fire({
                            title: 'Are you sure?',
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Yes, delete it!'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.sheetsData.splice(x, 1);
                                this.$apollo
                                    .mutate({
                                        mutation: gql`
                                mutation{
                                deleteSheet(ids:["${sheetid}"]){
                                    sheets{
                                    id
                                    }
                                }
                                }
                                `
                                    })
                                    .then((response) => {
                                        // Handle the response
                                        console.log(response);
                                        Swal.fire({
                                            title: 'Delete sheet successfully!',
                                            icon: 'success',
                                            showConfirmButton: false,
                                            timer: 1500
                                        })
                                        window.location.reload();
                                    })
                                    .catch((error) => {
                                        // Handle any errors
                                        console.error(error);
                                        Swal.fire({
                                            title: 'Something Wrong!',
                                            icon: 'error',
                                            showConfirmButton: false,
                                            timer: 1500
                                        })
                                        window.location.reload();
                                    });

                            }
                        })
                    }
                    else {
                        Swal.fire({
                            title: 'Sheet already used for dashboard!',
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                    
                } catch (error) {
                    // Handle any errors
                    console.error(error.message);
                }

        },
        newTab() {
            this.sheetsData.push(JSON.parse(JSON.stringify(this.sheetBlankData)));
        },
        clearxdata(clrxno, indx, xname) {
            this.sheetsData[indx].getxdata.splice(clrxno, 1);
            if (this.sheetsData[indx].getxdata.length <= 0) {
                this.sheetsData[indx].shwgraph = false;
                this.sheetsData[indx].graphtype = "table";
            }
            // if(this.sheetsData[indx].getcurrentshtdim.length > 0){
            //     this.sheetsData[indx].getcurrentshtdim.forEach((getcurrdim, gcindx)=>{
            //         if(getcurrdim.name == xname){
            //             this.sheetsData[indx].getcurrentshtdim.splice(gcindx, 1);                        
            //             if(this.sheetsData[this.currentActiveSheetIndex].sortobj[0].field == xname){
            //                 this.sheetsData[this.currentActiveSheetIndex].sortobj = [];
            //             }
            //         }
            //     });
            // }

            if (this.sheetsData[indx].getcurrencynumdata.length > 0) {
                this.sheetsData[indx].getcurrencynumdata.forEach((getcurrdta, gcurrindx) => {
                    if (getcurrdta.field == xname) {
                        this.sheetsData[indx].getcurrencynumdata.splice(gcurrindx, 1);
                    }
                });
            }

            if (this.sheetsData[this.currentActiveSheetIndex].sortobj.length > 0) {
                if (this.sheetsData[this.currentActiveSheetIndex].sortobj[0].field == xname) {
                    this.sheetsData[this.currentActiveSheetIndex].sortobj = [];
                }
            }

            if (this.sheetsData[this.currentActiveSheetIndex].currencyobj.length > 0) {
                this.sheetsData[this.currentActiveSheetIndex].currencyobj.forEach((getcurrobj, gcurrobjindx) => {
                    if (getcurrobj.field == xname) {
                        this.sheetsData[indx].currencyobj.splice(gcurrobjindx, 1);
                    }
                });
            }

            if (this.sheetsData[indx].graphtype != "pivot") {
                this.sheetsData[indx].getallmeasuresdata = [];
            }
            this.fetchData();
        },
        clearydata(clryno, indx, yname) {
            this.sheetsData[indx].getydata.splice(clryno, 1);
            if (this.sheetsData[indx].getydata.length <= 0) {
                this.sheetsData[indx].shwgraph = false;
                this.sheetsData[indx].graphtype = "table";
            }

            if (this.sheetsData[this.currentActiveSheetIndex].sortobj.length > 0) {
                if (this.sheetsData[this.currentActiveSheetIndex].sortobj[0].field == yname) {
                    this.sheetsData[this.currentActiveSheetIndex].sortobj = [];
                }
            }

            if (this.sheetsData[indx].getcurrencynumdata.length > 0) {
                this.sheetsData[indx].getcurrencynumdata.forEach((getcurrdta, gcurrindx) => {
                    if (getcurrdta.field == yname) {
                        this.sheetsData[indx].getcurrencynumdata.splice(gcurrindx, 1);
                    }
                });
            }
            if (this.sheetsData[this.currentActiveSheetIndex].currencyobj.length > 0) {
                this.sheetsData[this.currentActiveSheetIndex].currencyobj.forEach((getcurrobj, gcurrobjindx) => {
                    if (getcurrobj.field == yname) {
                        this.sheetsData[indx].currencyobj.splice(gcurrobjindx, 1);
                    }
                });
            }

            if (this.sheetsData[indx].graphtype != "pivot") {
                this.sheetsData[indx].getallmeasuresdata = [];
            }
            this.fetchData();
        },
        allowDrop(e) {
            e.preventDefault();
        },
        draglabel(e) {
            e.dataTransfer.setData("data-type", e.target.getAttribute("data-type"));
            e.dataTransfer.setData("date-type", e.target.getAttribute("date-type"));
            e.dataTransfer.setData("text", e.target.querySelector('b').innerText);
        },
        dragStart(e) {
            this.shwgraph = false;
            e.dataTransfer.setData("data-lable", e.target.getAttribute("data-lable"));
            e.dataTransfer.setData("text", e.target.innerText);
            e.dataTransfer.setData("data-type", e.target.getAttribute("data-type"));
            e.dataTransfer.setData("date-type", e.target.getAttribute("date-type"));
            e.dataTransfer.setData("data-field", e.target.getAttribute("data-field"));
        },
        dropStart(e) {
            e.preventDefault();
            var dropdata = e.dataTransfer.getData("text");
            var droptype = e.dataTransfer.getData("data-type");
            var datestatus = e.dataTransfer.getData("date-type");
            var dateyn = null;
            var dateenbdisb = "disabled";

            if (datestatus == "datetime64[ns]") {
                dateyn = "Select";
                dateenbdisb = "datetime64[ns]";
            }
            var objcattyp = { "name": dropdata, "type": droptype, aggregation: "Select", datestatus: dateyn, dateEnb: dateenbdisb, currencyformat: null, datename: dropdata };
            var objnumtyp = { "name": dropdata, "type": droptype, aggregation: "SUM", datestatus: dateyn, dateEnb: dateenbdisb, currencyformat: null, datename: dropdata };
            this.sheetsData[this.currentActiveSheetIndex].getxname = [];
            this.sheetsData[this.currentActiveSheetIndex].getyname = [];

            let formulist = [];
            this.formulasheetlisting.forEach((frmlist) => {
                formulist.push(frmlist.name);
            });

            if (this.sheetsData[this.currentActiveSheetIndex].getxdata.length > 0) {
                this.sheetsData[this.currentActiveSheetIndex].getxdata.forEach((xdta, xindx) => {
                    if (xdta.name == dropdata && xdta.dateEnb != "datetime64[ns]") {
                        this.sheetsData[this.currentActiveSheetIndex].getxdata.splice(xindx, 1);
                    }
                });
            }

            if (this.sheetsData[this.currentActiveSheetIndex].getydata.length > 0) {
                this.sheetsData[this.currentActiveSheetIndex].getydata.forEach((ydta, yindx) => {
                    if (ydta.name == dropdata && ydta.dateEnb != "datetime64[ns]") {
                        this.sheetsData[this.currentActiveSheetIndex].getydata.splice(yindx, 1);
                    }
                });
            }

            if (e.target.classList.contains('drop__xzone')) {
                if (this.getsheetdatasource.data.getDataSource.numericalDimensions.includes(dropdata)) {
                    if (this.sheetsData[this.currentActiveSheetIndex].graphtype != "pivot") {
                        this.sheetsData[this.currentActiveSheetIndex].getxdata.push(objnumtyp);
                    }
                    this.sheetsData[this.currentActiveSheetIndex].getallmeasuresdata.push(objnumtyp);
                }
                else if (formulist.includes(dropdata)) {
                    if (this.sheetsData[this.currentActiveSheetIndex].graphtype != "pivot") {
                        this.sheetsData[this.currentActiveSheetIndex].getxdata.push(objnumtyp);
                    }
                    this.sheetsData[this.currentActiveSheetIndex].getallmeasuresdata.push(objnumtyp);
                }
                else {
                    this.sheetsData[this.currentActiveSheetIndex].getxdata.push(objcattyp);
                }
            }
            else {
                if (this.getsheetdatasource.data.getDataSource.numericalDimensions.includes(dropdata)) {
                    if (this.sheetsData[this.currentActiveSheetIndex].graphtype != "pivot") {
                        this.sheetsData[this.currentActiveSheetIndex].getydata.push(objnumtyp);
                    }
                    this.sheetsData[this.currentActiveSheetIndex].getallmeasuresdata.push(objnumtyp);
                }
                else if (formulist.includes(dropdata)) {
                    if (this.sheetsData[this.currentActiveSheetIndex].graphtype != "pivot") {
                        this.sheetsData[this.currentActiveSheetIndex].getydata.push(objnumtyp);
                    }
                    this.sheetsData[this.currentActiveSheetIndex].getallmeasuresdata.push(objnumtyp);
                }
                else {
                    this.sheetsData[this.currentActiveSheetIndex].getydata.push(objcattyp);
                }
            }

            this.sheetsData[this.currentActiveSheetIndex].getxdata.forEach((xdata) => {
                this.sheetsData[this.currentActiveSheetIndex].getxname.push(xdata.name);
            })
            this.sheetsData[this.currentActiveSheetIndex].getydata.forEach((ydata) => {
                this.sheetsData[this.currentActiveSheetIndex].getyname.push(ydata.name);
            })


            if (this.sheetsData[this.currentActiveSheetIndex].getxname.includes(dropdata)) {
                if (this.sheetsData[this.currentActiveSheetIndex].getxdata[this.sheetsData[this.currentActiveSheetIndex].getxname.length - 1].dateEnb == "datetime64[ns]") {
                    this.repeateddatecolumns("x", this.sheetsData[this.currentActiveSheetIndex].getxname.length - 1);
                    this.duplicatedatemodal = true;
                }
            }

            if (this.sheetsData[this.currentActiveSheetIndex].getyname.includes(dropdata)) {
                if (this.sheetsData[this.currentActiveSheetIndex].getydata[this.sheetsData[this.currentActiveSheetIndex].getyname.length - 1].dateEnb == "datetime64[ns]") {
                    this.repeateddatecolumns("y", this.sheetsData[this.currentActiveSheetIndex].getyname.length - 1);
                    this.duplicatedatemodal = true;
                }
            }

            // if (e.target.classList.contains('drop__xzone')) {
            //     if (this.sheetsData[this.currentActiveSheetIndex].getxdata[this.sheetsData[this.currentActiveSheetIndex].getxname.length - 1].dateEnb == "datetime64[ns]") {
            //         this.repeateddatecolumns("x", this.sheetsData[this.currentActiveSheetIndex].getxname.length - 1);
            //         this.duplicatedatemodal = true;
            //     }
            // }
            // if (e.target.classList.contains('drop__yzone')) {
            //     if (this.sheetsData[this.currentActiveSheetIndex].getydata[this.sheetsData[this.currentActiveSheetIndex].getyname.length - 1].dateEnb == "datetime64[ns]") {
            //         this.repeateddatecolumns("y", this.sheetsData[this.currentActiveSheetIndex].getyname.length - 1);
            //         this.duplicatedatemodal = true;
            //     }
            // }


            this.shwascdsc = false;
            this.checknumcurrency(false);

            if (this.sheetsData[this.currentActiveSheetIndex].graphtype == "pivot") {
                // this.checkxypivotaxis();
                this.storepivotvalues();
            }

            this.fetchData();

        },
        async fetchData() {

            this.loader_status = true;
            this.sheetsData[this.currentActiveSheetIndex].shwtbl = false;
            this.sheetsData[this.currentActiveSheetIndex].shwgraph = false;
            this.sheetsData[this.currentActiveSheetIndex].shwpivottable = false;
            this.sheetsData[this.currentActiveSheetIndex].shwmultiplechartgraph = false;

            this.sheetsData[this.currentActiveSheetIndex].chartoptions.xAxis.show = false;
            this.sheetsData[this.currentActiveSheetIndex].chartoptions.yAxis.show = false;

            let xtyp = [];
            let ytyp = [];


            let noofcolumns = this.sheetsData[this.currentActiveSheetIndex].getxdata.length;
            let noofrows = this.sheetsData[this.currentActiveSheetIndex].getydata.length;
            let calfieldstatus = false;


            this.sheetsData[this.currentActiveSheetIndex].postagglist = [];
            this.sheetsData[this.currentActiveSheetIndex].expresslist = [];

            this.sheetsData[this.currentActiveSheetIndex].getxdata.forEach((dta) => {
                xtyp.push(dta.type);
                this.formulasheetlisting.forEach((fdta) => {
                    if (fdta.name == dta.name) {
                        fdta.formulafields.forEach(fildsobj => {
                            this.sheetsData[this.currentActiveSheetIndex].postagglist.push(fildsobj);
                        });
                        this.sheetsData[this.currentActiveSheetIndex].expresslist.push({ name: fdta.name, expression: fdta.formula });
                        calfieldstatus = true;
                    }
                });
            });

            this.sheetsData[this.currentActiveSheetIndex].getydata.forEach((dta) => {
                ytyp.push(dta.type);
                this.formulasheetlisting.forEach((fdta) => {
                    if (fdta.name == dta.name) {
                        fdta.formulafields.forEach(fildsobj => {
                            this.sheetsData[this.currentActiveSheetIndex].postagglist.push(fildsobj);
                        });
                        this.sheetsData[this.currentActiveSheetIndex].expresslist.push({ name: fdta.name, expression: fdta.formula });
                        calfieldstatus = true;
                    }
                });
            });

            if (this.sheetsData[this.currentActiveSheetIndex].graphtype == "pivot") {
                this.sheetsData[this.currentActiveSheetIndex].getallmeasuresdata.forEach((dta) => {
                    this.formulasheetlisting.forEach((fdta) => {
                        if (fdta.name == dta.name) {
                            fdta.formulafields.forEach(fildsobj => {
                                this.sheetsData[this.currentActiveSheetIndex].postagglist.push(fildsobj);
                            });
                            this.sheetsData[this.currentActiveSheetIndex].expresslist.push({ name: fdta.name, expression: fdta.formula });
                            calfieldstatus = true;
                        }
                    });
                });
            }

            if (this.sheetsData[this.currentActiveSheetIndex].graphtype == "pivot") {
                this.storepivotvalues();
            }

            if (noofcolumns == 0 && noofrows == 0) {
                this.loader_status = false;
            }

            if (this.sheetsData[this.currentActiveSheetIndex].graphtype == "table") {
                this.uploadtabledata(this.currentActiveSheetIndex, this.sheetsData[this.currentActiveSheetIndex].postagglist, this.sheetsData[this.currentActiveSheetIndex].expresslist);
            }

            if (this.sheetsData[this.currentActiveSheetIndex].graphtype == "pivot") {
                this.pivottable(this.currentActiveSheetIndex, this.sheetsData[this.currentActiveSheetIndex].postagglist, this.sheetsData[this.currentActiveSheetIndex].expresslist);
            }

            if (this.sheetsData[this.currentActiveSheetIndex].graphtype == "bar" || this.sheetsData[this.currentActiveSheetIndex].graphtype == "pie" || this.sheetsData[this.currentActiveSheetIndex].graphtype == "line") {
                this.loadsheetbardata(noofcolumns, noofrows, calfieldstatus);
            }

            if (this.sheetsData[this.currentActiveSheetIndex].graphtype == "scatter") {
                this.loadsheetbardata(noofcolumns, noofrows, calfieldstatus);
                // this.bothnumeric(this.currentActiveSheetIndex, this.sheetsData[this.currentActiveSheetIndex].postagglist, this.sheetsData[this.currentActiveSheetIndex].expresslist);
            }

            this.setgraphviewicon(xtyp, ytyp);


        },
        async pivottable(idx, postagglst, exprsslst) {
            let data1 = [];
            let data2 = [];
            let gedcols = [];
            let gedrow = [];
            let getnum = [];
            let response3 = null;
            let datedimxy = [];
            let currpge = 0;

            this.sheetsData[idx].getallmeasuresdata.forEach((numdata) => {
                getnum.push({ "uniqueName": numdata.name, "format": numdata.currencyformat });
                data2.push({ "name": numdata.name, "field": `${numdata.name}`, "type": `${numdata.aggregation}` });
            });

            this.sheetsData[idx].getxdata.forEach((xname) => {
                if (xname.type == "categories" && xname.dateEnb != "datetime64[ns]") {
                    gedcols.push({ "uniqueName": xname.name });
                }
                else {
                    gedcols.push({ "uniqueName": xname.name + xname.datestatus });
                }

                if (xname.aggregation != "Select") {
                    if (xname.datestatus != "Select" && xname.datestatus != null) {
                        datedimxy.push({
                            "dimension": xname.name,
                            "format": xname.datestatus,
                            "name": xname.name + xname.datestatus
                        });
                    }
                    // getnum.push({ "uniqueName": xname.name, "format": xname.currencyformat });
                    data2.push({ name: xname.name, field: xname.name, type: xname.aggregation });
                }
                else {
                    if (xname.datestatus != "Select" && xname.datestatus != null) {
                        datedimxy.push({
                            "dimension": xname.name,
                            "format": xname.datestatus,
                            "name": xname.name + xname.datestatus
                        });
                    }
                    else {
                        data1.push(xname.name);
                    }
                }
            });

            this.sheetsData[idx].getydata.forEach((yname) => {
                if (yname.type == "categories" && yname.dateEnb != "datetime64[ns]") {
                    gedrow.push({ "uniqueName": yname.name });
                }
                else {
                    gedrow.push({ "uniqueName": yname.name + yname.datestatus });
                }
                if (yname.aggregation != "Select") {
                    if (yname.datestatus != "Select" && yname.datestatus != null) {
                        datedimxy.push({
                            "dimension": yname.name,
                            "format": yname.datestatus,
                            "name": yname.name + yname.datestatus
                        });
                    }
                    // getnum.push({ "uniqueName": yname.name, "format": yname.currencyformat });
                    data2.push({ name: yname.name, field: yname.name, type: yname.aggregation });
                }
                else {
                    if (yname.datestatus != "Select" && yname.datestatus != null) {
                        datedimxy.push({
                            "dimension": yname.name,
                            "format": yname.datestatus,
                            "name": yname.name + yname.datestatus
                        });
                    }
                    else {
                        data1.push(yname.name);
                    }
                }
            });

            if (this.sheetsData[this.currentActiveSheetIndex].tablepagination.currentpage == 1) {
                currpge = 0;
            }
            else {
                currpge = (this.sheetsData[this.currentActiveSheetIndex].tablepagination.currentpage - 1) * this.sheetsData[this.currentActiveSheetIndex].tablepagination.pages;
            }

            const query3 = gql`
            query ChartData($input:DataSourceInput!){
                            getBarChart(input:$input){
                                chartType
                                totalRows
    							pivotData
                                nestedData
                                xaxis{
                                label
                                categories
                                }
                                series{
                                name
                                label

                                data
                                }
                            }
                            }
                        `;
            try {
                response3 = await this.$axios.post(`${this.$hostname}/graphql/`, {
                    query: query3.loc.source.body,
                    variables: {
                        "input": {
                            "datasource": this.sheetsData[idx].currentdatasource,
                            "chartType": "pivot",
                            "dimensions": data1,
                            "dateDimensions": datedimxy,
                            "pagination": {
                                "first": `${this.sheetsData[this.currentActiveSheetIndex].tablepagination.pages}`,
                                "skip": "0",
                                "offset": `${currpge}`
                            },
                            // "numericFormatting": this.sheetsData[this.currentActiveSheetIndex].currencyobj,
                            "aggregators": data2,
                            "filters": this.sheetsData[idx].filterobjlist,
                            "postAggregators": postagglst,
                            "expressions": exprsslst,
                            "sortingFields": this.sheetsData[idx].sortobj
                        } // Pass the variable value here
                    }, // Pass the GraphQL query string
                }, { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('token')}` } });

                // console.log(response3.data.data.getBarChart.pivotData, gedrow, gedcols, getnum);

                this.loader_status = false;
                this.sheetsData[idx].newpivotreport = {
                    dataSource: {
                        type: "json",
                        data: response3.data.data.getBarChart.pivotData
                    },
                    slice: {
                        rows: gedrow, // Set rows here
                        columns: gedcols,   // Set columns here
                        measures: getnum  // Set measures here
                    },
                    options: {
                        showFilter: false,
                        configuratorButton: false,
                        sorting: "off",
                        defaultHierarchySortName: "unsorted"                        
                    },
                    formats: [{
                        name: "indian"
                    },
                    {
                        name: "english",
                        currencySymbol: "$",
                        decimalPlaces: 2,
                        thousandsSeparator: ","
                    }]
                };

                this.sheetsData[idx].chartoptions.xAxis.show = false;
                this.sheetsData[idx].chartoptions.yAxis.show = false;
                this.sheetsData[idx].tablepagination.totalrows = response3.data.data.getBarChart.totalRows;

                this.sheetsData[this.currentActiveSheetIndex].shwgraph = false;
                this.sheetsData[this.currentActiveSheetIndex].shwtbl = false;
                this.sheetsData[this.currentActiveSheetIndex].shwmultiplechartgraph = false;
                this.sheetsData[this.currentActiveSheetIndex].shwpivottable = true;

                this.getactivatetab = parseInt(localStorage.getItem('activatetab'));

            } catch (error) {
                // Handle any errors
                console.error(error.message);
                this.loader_status = false;
            }
        },
        beforeDestroy() {
            // Dispose of the WebDataRocks instance when the component is destroyed
            this.sheetsData[idx].newpivotreport.dispose();
        },        
        async multiplechart(idno, data1, data2, xycategorystatus, postagglst, exprsslst) {
            let response3 = null;
            let data1data = [];
            let data2data = [];
            let datedata1data = [];

            if (data1.length > 0) {
                data1.forEach((dta1) => {
                    if (dta1.datestatus != "Select" && dta1.datestatus != null) {
                        datedata1data.push({
                            "dimension": dta1.name,
                            "format": dta1.datestatus,
                            "name": dta1.name + dta1.datestatus
                        });
                    }
                    else {
                        data1data.push(dta1.name);
                    }
                });
            }
            if (data2.length > 0) {
                data2.forEach((dta2) => {
                    data2data.push({ name: dta2.name, field: dta2.name, type: dta2.aggregation })
                });
            }

            this.sheetsData[idno].dimensionData = data1data;

            const query3 = gql`
                        query ChartData($input:DataSourceInput!){
                            getBarChart(input:$input){
                                chartType
                                nestedData
                                xaxis{
                                label
                                categories
                                }
                                series{
                                name
                                label

                                data
                                }
                            }
                            }
                        `;
            try {
                response3 = await this.$axios.post(`${this.$hostname}/graphql/`, {
                    query: query3.loc.source.body,
                    variables: {
                        "input": {
                            "datasource": this.sheetsData[idno].currentdatasource,
                            "chartType": "bar",
                            "dimensions": data1data,
                            "dateDimensions": datedata1data,
                            "pagination": {
                                "first": `${this.sheetsData[this.currentActiveSheetIndex].graphtopdata}`,
                                "skip": "0",
                                "offset": "0"
                            },
                            // "numericFormatting": this.sheetsData[this.currentActiveSheetIndex].currencyobj,
                            "aggregators": data2data,
                            "filters": this.sheetsData[idno].filterobjlist,
                            "postAggregators": postagglst,
                            "expressions": exprsslst,
                            "sortingFields": this.sheetsData[idno].sortobj
                        } // Pass the variable value here
                    }, // Pass the GraphQL query string
                }, { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('token')}` } });

                // console.log(response3);
                this.sheetsData[idno].chartoptions.xAxis.data = [];
                this.sheetsData[idno].chartoptions.yAxis.data = [];
                this.sheetsData[idno].chartoptions.series = [];

                if (this.sheetsData[idno].graphtype == "bar" || this.sheetsData[idno].graphtype == "line" || this.sheetsData[idno].graphtype == "scatter") {
                    let newserdata = [];
                    if (xycategorystatus == "colcat") {
                        this.sheetsData[idno].chartoptions.xAxis.type = "category";
                        this.sheetsData[idno].chartoptions.yAxis.type = "value";
                        if (response3.data.data.getBarChart.xaxis.length > 0) {
                            this.sheetsData[idno].chartoptions.xAxis.data = response3.data.data.getBarChart.xaxis[0].categories;
                        }
                        else {
                            response3.data.data.getBarChart.series.forEach((sitm) => {
                                this.sheetsData[idno].chartoptions.xAxis.data.push(sitm.name);
                            });
                        }
                    }
                    if (xycategorystatus == "rwcat") {
                        this.sheetsData[idno].chartoptions.yAxis.type = "category";
                        this.sheetsData[idno].chartoptions.xAxis.type = "value";
                        if (response3.data.data.getBarChart.xaxis.length > 0) {
                            this.sheetsData[idno].chartoptions.yAxis.data = response3.data.data.getBarChart.xaxis[0].categories;
                        }
                        else {
                            response3.data.data.getBarChart.series.forEach((sitm) => {
                                this.sheetsData[idno].chartoptions.yAxis.data.push(sitm.name);
                            });
                        }
                    }

                    response3.data.data.getBarChart.series.forEach((sitm) => {
                        newserdata.push({
                            name: sitm.name,
                            barGap: 0,
                            data: sitm.data,
                            type: this.sheetsData[idno].graphtype,
                            tooltip: {
                                formatter: this.valueFormatter = (params) => {
                                    var val = null;
                                    if (this.sheetsData[this.currentActiveSheetIndex].currencyobj.length > 0) {
                                        this.sheetsData[this.currentActiveSheetIndex].currencyobj.forEach((curobj) => {
                                            if (params.seriesName == curobj.field) {
                                                if (curobj.format != null && curobj.format != "select") {
                                                    if (curobj.format == "indian") {
                                                        val = sitm.name + '<br />' + '₹ ' + params.value;
                                                    }
                                                    if (curobj.format == "english") {
                                                        val = sitm.name + '<br />' + '$ ' + params.value;
                                                    }
                                                }
                                            }
                                            this.sheetsData[this.currentActiveSheetIndex].getcurrencynumdata.forEach((numdta) => {
                                                if (params.seriesName == numdta.field && (numdta.format == null || numdta.format == "select")) {
                                                    val = sitm.name + '<br />' + params.value;
                                                }
                                            });
                                        });
                                    }
                                    else {
                                        val = sitm.name + '<br />' + params.value;
                                    }

                                    return val;
                                }
                            },
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 3,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            }
                        });

                        this.sheetsData[idno].chartoptions.legend.data.push(sitm.name);
                    });
                    this.sheetsData[idno].chartoptions.series = newserdata;

                }

                this.sheetsData[idno].chartoptions.xAxis.show = true;
                this.sheetsData[idno].chartoptions.yAxis.show = true;

                this.sheetsData[this.currentActiveSheetIndex].shwtbl = false;
                this.sheetsData[this.currentActiveSheetIndex].shwpivottable = false;
                this.sheetsData[this.currentActiveSheetIndex].shwmultiplechartgraph = false;
                this.sheetsData[this.currentActiveSheetIndex].shwgraph = true;

                this.loader_status = false;
                this.getactivatetab = parseInt(localStorage.getItem('activatetab'));

            } catch (error) {
                // Handle any errors
                console.error(error.message);
                this.loader_status = false;
            }
        },
        async multipledimensioncharts(idno, data1, data2, xycategorystatus, postagglst, exprsslst) {
            let response3 = null;
            let data1data = [];
            let data2data = [];
            let datedata1data = [];

            if (data1.length > 0) {
                data1.forEach((dta1) => {
                    if (dta1.datestatus != "Select" && dta1.datestatus != null) {
                        datedata1data.push({
                            "dimension": dta1.name,
                            "format": dta1.datestatus,
                            "name": dta1.name + dta1.datestatus
                        });
                    }
                    else {
                        data1data.push(dta1.name);
                    }
                });
            }
            if (data2.length > 0) {
                data2.forEach((dta2) => {
                    data2data.push({ name: dta2.name, field: dta2.name, type: dta2.aggregation })
                });
            }

            this.sheetsData[idno].dimensionData = data1data;

            const query3 = gql`
                        query ChartData($input:DataSourceInput!){
                            getBarChart(input:$input){
                                chartType
                                nestedData
                                xaxis{
                                label
                                categories
                                }
                                series{
                                name
                                label

                                data
                                }
                            }
                            }
                        `;
            try {
                response3 = await this.$axios.post(`${this.$hostname}/graphql/`, {
                    query: query3.loc.source.body,
                    variables: {
                        "input": {
                            "datasource": this.sheetsData[idno].currentdatasource,
                            "chartType": "bar",
                            "dimensions": data1data,
                            "dateDimensions": datedata1data,
                            "pagination": {
                                "first": `${this.sheetsData[this.currentActiveSheetIndex].graphtopdata}`,
                                "skip": "0",
                                "offset": "0"
                            },
                            // "numericFormatting": this.sheetsData[this.currentActiveSheetIndex].currencyobj,
                            "aggregators": data2data,
                            "filters": this.sheetsData[idno].filterobjlist,
                            "postAggregators": postagglst,
                            "expressions": exprsslst,
                            "sortingFields": this.sheetsData[idno].sortobj
                        } // Pass the variable value here
                    }, // Pass the GraphQL query string
                }, { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('token')}` } });


                this.sheetsData[idno].multiplechartoptions = [];

                data1.forEach((dim, dind) => {
                    this.sheetsData[idno].multiplechartoptions.push({
                        tooltip: {
                            show: true
                        },
                        xAxis: {
                            type: "category",
                            data: [],
                            show: false
                        },
                        yAxis: {
                            type: "value",
                            data: [],
                            show: false
                        },
                        legend: {
                            data: []
                        },
                        dataZoom: [
                            {
                                type: 'slider',
                                height: 15,
                                show: true
                            },
                            {
                                type: 'inside'
                            }
                        ],
                        color: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
                        dataset: {
                            dimensions: [],
                            source: []
                        },
                        series: [
                            {
                                data: [],
                                type: 'bar',
                                tooltip: {},
                                emphasis: {
                                    itemStyle: {
                                        shadowBlur: 3,
                                        shadowOffsetX: 0,
                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                    }
                                }
                            }
                        ]
                    });
                });


                this.sheetsData[idno].multiplechartoptions.forEach((chartopt, chartidx) => {

                    if (this.sheetsData[idno].graphtype == "bar" || this.sheetsData[idno].graphtype == "line" || this.sheetsData[idno].graphtype == "scatter") {
                        let newserdata = [];
                        if (xycategorystatus == "colcat") {
                            chartopt.xAxis.type = "category";
                            chartopt.yAxis.type = "value";
                            if (response3.data.data.getBarChart.xaxis.length > 0) {
                                chartopt.xAxis.data = response3.data.data.getBarChart.xaxis[chartidx].categories;
                            }
                            else {
                                response3.data.data.getBarChart.series.forEach((sitm) => {
                                    chartopt.xAxis.data.push(sitm.name);
                                });
                            }
                        }
                        if (xycategorystatus == "rwcat") {
                            chartopt.yAxis.type = "category";
                            chartopt.xAxis.type = "value";
                            if (response3.data.data.getBarChart.xaxis.length > 0) {
                                chartopt.yAxis.data = response3.data.data.getBarChart.xaxis[chartidx].categories;
                            }
                            else {
                                response3.data.data.getBarChart.series.forEach((sitm) => {
                                    chartopt.yAxis.data.push(sitm.name);
                                });
                            }
                        }

                        response3.data.data.getBarChart.series.forEach((sitm) => {
                            newserdata.push({
                                name: sitm.name,
                                barGap: 0,
                                data: sitm.data,
                                type: this.sheetsData[idno].graphtype,
                                tooltip: {
                                    formatter: this.valueFormatter = (params) => {
                                        var val = null;
                                        if (this.sheetsData[this.currentActiveSheetIndex].currencyobj.length > 0) {
                                            this.sheetsData[this.currentActiveSheetIndex].currencyobj.forEach((curobj) => {
                                                if (params.seriesName == curobj.field) {
                                                    if (curobj.format != null && curobj.format != "select") {
                                                        if (curobj.format == "indian") {
                                                            val = sitm.name + '<br />' + '₹ ' + params.value;
                                                        }
                                                        if (curobj.format == "english") {
                                                            val = sitm.name + '<br />' + '$ ' + params.value;
                                                        }
                                                    }
                                                }
                                                this.sheetsData[this.currentActiveSheetIndex].getcurrencynumdata.forEach((numdta) => {
                                                    if (params.seriesName == numdta.field && (numdta.format == null || numdta.format == "select")) {
                                                        val = sitm.name + '<br />' + params.value;
                                                    }
                                                });
                                            });
                                        }
                                        else {
                                            val = sitm.name + '<br />' + params.value;
                                        }

                                        return val;
                                    }
                                },
                                emphasis: {
                                    itemStyle: {
                                        shadowBlur: 3,
                                        shadowOffsetX: 0,
                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                    }
                                }
                            });

                            chartopt.legend.data.push(sitm.name);
                        });
                        chartopt.series = newserdata;
                    }
                    chartopt.xAxis.show = true;
                    chartopt.yAxis.show = true;
                });

                this.sheetsData[this.currentActiveSheetIndex].shwtbl = false;
                this.sheetsData[this.currentActiveSheetIndex].shwpivottable = false;
                this.sheetsData[this.currentActiveSheetIndex].shwgraph = false;
                this.sheetsData[this.currentActiveSheetIndex].shwmultiplechartgraph = true;

                this.loader_status = false;
                this.getactivatetab = parseInt(localStorage.getItem('activatetab'));


            } catch (error) {
                // Handle any errors
                console.error(error.message);
                this.loader_status = false;
            }
        },
        async bothnumeric(ind, postagglst, exprsslst) {
            let response3 = null;
            let xydata = this.sheetsData[this.currentActiveSheetIndex].getxdata.concat(this.sheetsData[this.currentActiveSheetIndex].getydata);
            let xyobj = [];
            xydata.forEach((elm, indx) => {
                xyobj.push({ "name": elm.name, "field": elm.name, "type": elm.aggregation });
            });

            const query3 = gql`
                        query ChartData($input:DataSourceInput!){
                            getBarChart(input:$input){
                                chartType
                                nestedData
                                xaxis{
                                label
                                categories
                                }
                                series{
                                name
                                label

                                data
                                }
                            }
                            }
                        `;
            try {
                response3 = await this.$axios.post(`${this.$hostname}/graphql/`, {
                    query: query3.loc.source.body,
                    variables: {
                        "input": {
                            "datasource": this.sheetsData[ind].currentdatasource,
                            "chartType": "bar",
                            "dimensions": [],
                            "numericFormatting": this.sheetsData[this.currentActiveSheetIndex].currencyobj,
                            "filters": this.sheetsData[ind].filterobjlist,
                            "aggregators": xyobj,
                            "postAggregators": postagglst,
                            "expressions": exprsslst,
                            "sortingFields": this.sheetsData[ind].sortobj
                        } // Pass the variable value here
                    }, // Pass the GraphQL query string
                }, { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('token')}` } });

                let serarray = [];
                response3.data.data.getBarChart.series.forEach((dta) => {
                    serarray.push(dta.data[0])
                });


                this.loader_status = false;

                this.sheetsData[ind].chartoptions.xAxis.data = [];
                this.sheetsData[ind].chartoptions.yAxis.data = [];
                this.sheetsData[ind].chartoptions.series[0].data = [];

                this.sheetsData[ind].chartoptions.series[0].data = serarray;
                this.sheetsData[ind].chartoptions.xAxis.data = null;
                this.sheetsData[ind].chartoptions.yAxis.data = null;
                this.sheetsData[ind].chartoptions.xAxis.type = null;
                this.sheetsData[ind].chartoptions.yAxis.type = null;

                this.sheetsData[ind].chartoptions.xAxis.show = true;
                this.sheetsData[ind].chartoptions.yAxis.show = true;

                this.sheetsData[this.currentActiveSheetIndex].shwtbl = false;
                this.sheetsData[this.currentActiveSheetIndex].shwpivottable = false;
                this.sheetsData[this.currentActiveSheetIndex].shwmultiplechartgraph = false;
                this.sheetsData[this.currentActiveSheetIndex].shwgraph = true;

                this.getactivatetab = parseInt(localStorage.getItem('activatetab'));

            } catch (error) {
                // Handle any errors
                console.error(error.message);
                this.loader_status = false;
            }
        },
        async uploadtabledata(idx, postagglst, exprsslst) {
            let xydata = [];
            let aggrigatorslist = [];
            let datedimxy = [];
            let xdtarows = [];
            let ydtacolumns = [];
            let paginationobj = {};
            let currpge = 0;

            this.sheetsData[idx].getxdata.forEach((xyd) => {
                if (xyd.aggregation != "Select") {
                    if (xyd.datestatus != "Select" && xyd.datestatus != null) {
                        datedimxy.push({
                            "dimension": xyd.name,
                            "format": xyd.datestatus,
                            "name": xyd.name + xyd.datestatus
                        });
                        xdtarows.push(xyd.name + xyd.datestatus);
                    }
                    else {
                        xdtarows.push(xyd.name);
                    }
                    aggrigatorslist.push({ name: xyd.name, field: xyd.name, type: xyd.aggregation });
                }
                else {
                    if (xyd.datestatus != "Select" && xyd.datestatus != null) {
                        datedimxy.push({
                            "dimension": xyd.name,
                            "format": xyd.datestatus,
                            "name": xyd.name + xyd.datestatus
                        });
                        xdtarows.push(xyd.name + xyd.datestatus);
                    }
                    else {
                        xydata.push(xyd.name);
                        xdtarows.push(xyd.name);
                    }
                }

            });

            this.sheetsData[idx].getydata.forEach((yxd) => {
                if (yxd.aggregation != "Select") {
                    if (yxd.datestatus != "Select" && yxd.datestatus != null) {
                        datedimxy.push({
                            "dimension": yxd.name,
                            "format": yxd.datestatus,
                            "name": yxd.name + yxd.datestatus
                        });
                        ydtacolumns.push(yxd.name + yxd.datestatus);
                    }
                    else {
                        ydtacolumns.push(yxd.name);
                    }
                    aggrigatorslist.push({ name: yxd.name, field: yxd.name, type: yxd.aggregation });
                }
                else {
                    if (yxd.datestatus != "Select" && yxd.datestatus != null) {
                        datedimxy.push({
                            "dimension": yxd.name,
                            "format": yxd.datestatus,
                            "name": yxd.name + yxd.datestatus
                        });
                        ydtacolumns.push(yxd.name + yxd.datestatus);
                    }
                    else {
                        xydata.push(yxd.name);
                        ydtacolumns.push(yxd.name);
                    }
                }

            });

            if (this.sheetsData[this.currentActiveSheetIndex].tablepagination.currentpage == 1) {
                currpge = 0;
            }
            else {
                currpge = (this.sheetsData[this.currentActiveSheetIndex].tablepagination.currentpage - 1) * this.sheetsData[this.currentActiveSheetIndex].tablepagination.pages;
            }

            if (xydata.length == 0) {
                paginationobj = {
                    "first": `${this.sheetsData[this.currentActiveSheetIndex].tablepagination.pages}`,
                    "skip": "0",
                    "offset": "0"
                }
            }
            else {
                paginationobj = {
                    "first": `${this.sheetsData[this.currentActiveSheetIndex].tablepagination.pages}`,
                    "skip": "0",
                    "offset": `${currpge}`
                }
            }

            const catequery = gql`
                query ChartData($input: DataSourceInput!) {
                    getBarChart(input: $input) {
                        chartType
                        totalRows
                        nestedData
                        pivotData
                        xaxis {
                        label
                        categories
                        }
                        series {
                        name
                        label
                        data
                        }
                    }
                    }
                    `;

            try {
                const categoryresponse = await this.$axios.post(`${this.$hostname}/graphql/`, {
                    query: catequery.loc.source.body,
                    variables: {
                        "input": {
                            "datasource": this.sheetsData[idx].currentdatasource,
                            "dimensions": xydata,
                            "dateDimensions": datedimxy,
                            "numericFormatting": this.sheetsData[this.currentActiveSheetIndex].currencyobj,
                            "rowsOrder": xdtarows,
                            "columnsOrder": ydtacolumns,
                            "pagination": paginationobj,
                            "filters": this.sheetsData[idx].filterobjlist,
                            "chartType": "pivot",
                            "aggregators": aggrigatorslist,
                            "postAggregators": postagglst,
                            "expressions": exprsslst,
                            "sortingFields": this.sheetsData[idx].sortobj
                        } // Pass the variable value here
                    }, // Pass the GraphQL query string
                }, { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('token')}` } });

                // console.log(categoryresponse);

                this.sheetsData[idx].tableth = Object.keys(categoryresponse.data.data.getBarChart.pivotData[0]);
                this.sheetsData[idx].tabledata = categoryresponse.data.data.getBarChart.pivotData;

                this.loader_status = false;
                this.sheetsData[idx].chartoptions.xAxis.show = false;
                this.sheetsData[idx].chartoptions.yAxis.show = false;
                this.sheetsData[this.currentActiveSheetIndex].tablepagination.totalrows = categoryresponse.data.data.getBarChart.totalRows;
                this.sheetsData[idx].tablegrandtotal = [];

                let calculatednames = [];

                this.formulasheetlisting.forEach((frmshtlist) => {
                    calculatednames.push(frmshtlist.name);
                });

                if (aggrigatorslist.length > 0 || postagglst.length > 0) {
                    this.sheetsData[idx].tableth.forEach((tblth) => {
                        if (this.getsheetdatasource.data.getDataSource.numericalDimensions.includes(tblth) || calculatednames.includes(tblth)) {
                            let sum = 0;

                            if (this.sheetsData[idx].getcurrencynumdata.length > 0) {
                                this.sheetsData[idx].getcurrencynumdata.forEach((currobj) => {
                                    if (currobj.field == tblth) {
                                        if (currobj.format == "indian") {
                                            categoryresponse.data.data.getBarChart.pivotData.forEach((tbldta) => {
                                                sum += parseFloat(tbldta[tblth].replace('₹', '').replace(/\,/g, ''));
                                            });
                                            this.sheetsData[idx].tablegrandtotal.push('₹' + new Intl.NumberFormat("en-IN").format(sum));
                                        }
                                        if (currobj.format == "english") {
                                            categoryresponse.data.data.getBarChart.pivotData.forEach((tbldta) => {
                                                sum += parseFloat(tbldta[tblth].replace('$', '').replace(/\,/g, ''));
                                            });
                                            this.sheetsData[idx].tablegrandtotal.push('$' + new Intl.NumberFormat("en-US").format(sum));
                                        }
                                        if (currobj.format == "select" || currobj.format == null) {
                                            categoryresponse.data.data.getBarChart.pivotData.forEach((tbldta) => {
                                                sum += parseFloat(tbldta[tblth]);
                                            });
                                            this.sheetsData[idx].tablegrandtotal.push(new Intl.NumberFormat("en-US").format(sum));
                                        }

                                    }
                                });

                                // console.log(new Intl.NumberFormat("en-IN").format(sum),  sum);


                            }
                            else {
                                categoryresponse.data.data.getBarChart.pivotData.forEach((tbldta) => {
                                    sum += tbldta[tblth];
                                });
                                this.sheetsData[idx].tablegrandtotal.push(new Intl.NumberFormat("en-IN").format(sum));
                            }

                        }
                        else {
                            this.sheetsData[idx].tablegrandtotal.push(null);
                        }
                    });

                }

                this.sheetsData[this.currentActiveSheetIndex].shwpivottable = false;
                this.sheetsData[this.currentActiveSheetIndex].shwgraph = false;
                this.sheetsData[this.currentActiveSheetIndex].shwmultiplechartgraph = false;
                this.sheetsData[this.currentActiveSheetIndex].shwtbl = true;

                this.getactivatetab = parseInt(localStorage.getItem('activatetab'));

            } catch (error) {
                // Handle any errors
                console.error(error.message);
                this.loader_status = false;
            }
        },
        async numandcategory(idno, data1, data2, xycategorystatus, postagglst, exprsslst) {
            let response3 = null;
            let data1data = [];
            let data2data = [];
            let datedata1data = [];

            if (data1.length > 0) {
                if (data1[0].datestatus != "Select" && data1[0].datestatus != null) {
                    datedata1data.push({
                        "dimension": data1[0].name,
                        "format": data1[0].datestatus,
                        "name": data1[0].name + data1[0].datestatus
                    });
                }
                else {
                    data1data.push(data1[0].name);
                }
            }
            if (data2.length > 0) {
                data2data.push({ name: data2[0].name, field: data2[0].name, type: data2[0].aggregation })
            }

            this.sheetsData[idno].dimensionData = data1data;

            const query3 = gql`
                        query ChartData($input:DataSourceInput!){
                            getBarChart(input:$input){
                                chartType
                                nestedData
                                xaxis{
                                label
                                categories
                                }
                                series{
                                name
                                label

                                data
                                }
                            }
                            }
                        `;
            try {
                response3 = await this.$axios.post(`${this.$hostname}/graphql/`, {
                    query: query3.loc.source.body,
                    variables: {
                        "input": {
                            "datasource": this.sheetsData[idno].currentdatasource,
                            "chartType": "bar",
                            "dimensions": data1data,
                            "dateDimensions": datedata1data,
                            "pagination": {
                                "first": `${this.sheetsData[this.currentActiveSheetIndex].graphtopdata}`,
                                "skip": "0",
                                "offset": "0"
                            },
                            // "numericFormatting": this.sheetsData[this.currentActiveSheetIndex].currencyobj,
                            "aggregators": data2data,
                            "filters": this.sheetsData[idno].filterobjlist,
                            "postAggregators": postagglst,
                            "expressions": exprsslst,
                            "sortingFields": this.sheetsData[idno].sortobj
                        } // Pass the variable value here
                    }, // Pass the GraphQL query string
                }, { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('token')}` } });

                this.sheetsData[idno].chartoptions.xAxis.data = [];
                this.sheetsData[idno].chartoptions.yAxis.data = [];
                this.sheetsData[idno].chartoptions.series = [{
                    data: [],
                    type: this.sheetsData[idno].graphtype,
                    tooltip: {},
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 3,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }];

                // console.log(response3);

                if (this.sheetsData[this.currentActiveSheetIndex].currencyobj.length > 0) {
                    if (this.sheetsData[this.currentActiveSheetIndex].currencyobj[0].format != null && this.sheetsData[this.currentActiveSheetIndex].currencyobj[0].format != "select") {
                        response3.data.data.getBarChart.series[0].data.map(flt => parseFloat(parseInt(flt))).forEach((resval) => {
                            this.sheetsData[this.currentActiveSheetIndex].chartoptions.series[0].tooltip.valueFormatter = (dval) => {
                                if (this.sheetsData[this.currentActiveSheetIndex].currencyobj[0].format == "indian") {
                                    return '₹ ' + dval;
                                }
                                if (this.sheetsData[this.currentActiveSheetIndex].currencyobj[0].format == "english") {
                                    return '$ ' + dval;
                                }
                            }
                        })
                    }
                }
                else {
                    this.sheetsData[this.currentActiveSheetIndex].chartoptions.series[0].tooltip = {};
                }

                if (this.sheetsData[idno].graphtype == "bar" || this.sheetsData[idno].graphtype == "line") {
                    if (xycategorystatus == "xcat") {
                        this.sheetsData[idno].chartoptions.xAxis.type = "category";
                        this.sheetsData[idno].chartoptions.yAxis.type = "value";
                        if (response3.data.data.getBarChart.xaxis.length > 0) {
                            this.sheetsData[idno].chartoptions.xAxis.data = response3.data.data.getBarChart.xaxis[0].categories;
                        }
                        else {
                            this.sheetsData[idno].chartoptions.xAxis.data.push(response3.data.data.getBarChart.series[0].name);
                        }
                        this.sheetsData[idno].chartoptions.series[0].data = response3.data.data.getBarChart.series[0].data.map(flt => parseFloat(parseInt(flt)));
                    }
                    if (xycategorystatus == "ycat") {
                        this.sheetsData[idno].chartoptions.yAxis.type = "category";
                        this.sheetsData[idno].chartoptions.xAxis.type = "value";
                        if (response3.data.data.getBarChart.xaxis.length > 0) {
                            this.sheetsData[idno].chartoptions.yAxis.data = response3.data.data.getBarChart.xaxis[0].categories;
                        }
                        else {
                            this.sheetsData[idno].chartoptions.yAxis.data.push(response3.data.data.getBarChart.series[0].name);
                        }

                        this.sheetsData[idno].chartoptions.series[0].data = response3.data.data.getBarChart.series[0].data.map(flt => parseFloat(parseInt(flt)));
                    }
                }

                if (this.sheetsData[idno].prevclr == 0) {
                    this.sheetsData[idno].prevclr++;
                    this.sheetsData[idno].getpreviouscolor = this.sheetsData[idno].chartoptions.color;
                }
                if (this.sheetsData[idno].graphtype == "pie") {
                    this.sheetsData[idno].chartoptions.xAxis.show = false;
                    this.sheetsData[idno].chartoptions.yAxis.show = false;
                    let createseriesdata = [];
                    let createseriesobj = {};
                    this.sheetsData[idno].chartoptions.dataZoom[0].show = false;
                    if (response3.data.data.getBarChart.series.length > 0 && response3.data.data.getBarChart.xaxis.length == 0) {
                        response3.data.data.getBarChart.series[0].data.forEach((dta) => {
                            createseriesobj = { value: dta, name: response3.data.data.getBarChart.series[0].label };
                            createseriesdata.push(createseriesobj);
                        });
                    }
                    else {
                        response3.data.data.getBarChart.series[0].data.forEach((dta, idx) => {
                            createseriesobj = { value: dta, name: response3.data.data.getBarChart.xaxis[0].categories[idx] };
                            createseriesdata.push(createseriesobj);
                        });
                    }

                    this.sheetsData[idno].chartoptions.series[0].data = createseriesdata;
                    this.sheetsData[idno].chartoptions.color = [];
                    this.sheetsData[idno].chartoptions.color = ['#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
                        '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
                        '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
                        '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
                        '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
                        '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
                        '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
                        '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
                        '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
                        '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'];

                }
                else {
                    this.sheetsData[idno].chartoptions.color = [];
                    this.sheetsData[idno].chartoptions.color = this.sheetsData[idno].getpreviouscolor;
                    this.sheetsData[idno].chartoptions.xAxis.show = true;
                    this.sheetsData[idno].chartoptions.yAxis.show = true;
                    this.sheetsData[idno].chartoptions.dataZoom[0].show = true;
                }

                this.loader_status = false;

                this.sheetsData[this.currentActiveSheetIndex].shwtbl = false;
                this.sheetsData[this.currentActiveSheetIndex].shwpivottable = false;
                this.sheetsData[this.currentActiveSheetIndex].shwmultiplechartgraph = false;
                this.sheetsData[this.currentActiveSheetIndex].shwgraph = true;

                this.getactivatetab = parseInt(localStorage.getItem('activatetab'));

            } catch (error) {
                // Handle any errors
                console.error(error.message);
                this.loader_status = false;
            }
        },
        customizeToolbar(toolbar) {
            var tabs = toolbar.getTabs(); // get all tabs from the toolbar
            toolbar.getTabs = function () {
                let gettab3 = tabs[3];
                let gettab5 = tabs[5];
                tabs = [];
                tabs.push(gettab3);
                tabs.push(gettab5);
                return tabs;
            }
        },
        customizeCellFunction(cell, data) {
            if (data.measure && data.type != 'header' && data.measure.format == "indian") {
                cell.text = data.value.toLocaleString("en-IN", {
                maximumFractionDigits: 2,
                style: "currency",
                currency: "INR"
                });
            }
        },
        logoutuser() {
            this.$apollo
                .mutate({
                    mutation: gql`
                    mutation LogOutUser($token:String!){
                        logout(token:$token){
                            success
                        }
                    }
                            `,
                    variables: {
                        "token": localStorage.getItem("token")
                    },
                })
                .then((response) => {
                    if (response) {
                        Swal.fire({
                            title: 'Logout successfully!',
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        localStorage.clear();
                        this.$router.push({ name: 'login' })
                    }
                })
                .catch((error) => {
                    if (error) {
                        Swal.fire({
                            title: 'Something wrong!',
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                });
        },
        /*------------------- fetch data functions start --------------------------*/
        loadsheetbardata(noofcolumns, noofrows, calfieldstatus) {

            let xnumeric = false;
            let ynumeric = false;
            let emptyarray = [];
            let formulasheetnamelist = [];
            let getxdta = this.sheetsData[this.currentActiveSheetIndex].getxdata;
            let getydta = this.sheetsData[this.currentActiveSheetIndex].getydata;

            if (this.formulasheetlisting.length > 0) {
                this.formulasheetlisting.forEach((frmshtlist) => {
                    formulasheetnamelist.push(frmshtlist.name);
                });
            }

            if (noofcolumns == 1 && noofrows == 0) {
                if (this.getsheetdatasource.data.getDataSource.numericalDimensions.includes(getxdta[0].name)) {
                    this.numandcategory(this.currentActiveSheetIndex, getydta, getxdta, "ycat", this.sheetsData[this.currentActiveSheetIndex].postagglist, this.sheetsData[this.currentActiveSheetIndex].expresslist);
                }
            }
            if (noofcolumns == 0 && noofrows == 1) {
                if (this.getsheetdatasource.data.getDataSource.numericalDimensions.includes(getydta[0].name)) {
                    this.numandcategory(this.currentActiveSheetIndex, getxdta, getydta, "xcat", this.sheetsData[this.currentActiveSheetIndex].postagglist, this.sheetsData[this.currentActiveSheetIndex].expresslist);
                }
            }
            if (noofcolumns > 0 || noofrows > 0) {
                if (noofcolumns == 1 || noofrows == 1) {
                    if (getxdta.length > 0) {
                        if (this.getsheetdatasource.data.getDataSource.numericalDimensions.includes(getxdta[0].name)) {
                            xnumeric = true;
                        }
                        if (formulasheetnamelist.includes(getxdta[0].name)) {
                            xnumeric = true;
                        }
                    }
                    if (getydta.length > 0) {
                        if (this.getsheetdatasource.data.getDataSource.numericalDimensions.includes(getydta[0].name)) {
                            ynumeric = true;
                        }
                        if (formulasheetnamelist.includes(getydta[0].name)) {
                            ynumeric = true;
                        }
                    }

                    if (ynumeric == true && xnumeric == false && (getxdta.length == 1 && getydta.length == 1)) {
                        this.numandcategory(this.currentActiveSheetIndex, getxdta, getydta, "xcat", this.sheetsData[this.currentActiveSheetIndex].postagglist, this.sheetsData[this.currentActiveSheetIndex].expresslist);
                    }
                    if (ynumeric == false && xnumeric == true && (getxdta.length == 1 && getydta.length == 1)) {
                        this.numandcategory(this.currentActiveSheetIndex, getydta, getxdta, "ycat", this.sheetsData[this.currentActiveSheetIndex].postagglist, this.sheetsData[this.currentActiveSheetIndex].expresslist);
                    }

                    if (ynumeric == true && getxdta.length == 0) {
                        if (calfieldstatus) {
                            this.numandcategory(this.currentActiveSheetIndex, getxdta, emptyarray, "xcat", this.sheetsData[this.currentActiveSheetIndex].postagglist, this.sheetsData[this.currentActiveSheetIndex].expresslist);
                        }
                        else {
                            this.numandcategory(this.currentActiveSheetIndex, getxdta, getydta, "xcat", this.sheetsData[this.currentActiveSheetIndex].postagglist, this.sheetsData[this.currentActiveSheetIndex].expresslist);
                        }
                    }
                    if (xnumeric == true && getydta.length == 0) {
                        if (calfieldstatus) {
                            this.numandcategory(this.currentActiveSheetIndex, getydta, emptyarray, "ycat", this.sheetsData[this.currentActiveSheetIndex].postagglist, this.sheetsData[this.currentActiveSheetIndex].expresslist);
                        }
                        else {
                            this.numandcategory(this.currentActiveSheetIndex, getydta, getxdta, "ycat", this.sheetsData[this.currentActiveSheetIndex].postagglist, this.sheetsData[this.currentActiveSheetIndex].expresslist);
                        }
                    }
                    if (ynumeric == false && getxdta.length == 0) {
                        if (getydta[0].aggregation == "COUNT") {
                            this.numandcategory(this.currentActiveSheetIndex, emptyarray, getydta, "ycat", this.sheetsData[this.currentActiveSheetIndex].postagglist, this.sheetsData[this.currentActiveSheetIndex].expresslist);
                        }
                        this.loader_status = false;
                    }
                    if (xnumeric == false && getydta.length == 0) {
                        if (getxdta[0].aggregation == "COUNT") {
                            this.numandcategory(this.currentActiveSheetIndex, emptyarray, getxdta, "xcat", this.sheetsData[this.currentActiveSheetIndex].postagglist, this.sheetsData[this.currentActiveSheetIndex].expresslist);
                        }
                        this.loader_status = false;
                    }
                    if (xnumeric == false && ynumeric == false && getxdta.length > 0 && getydta.length > 0) {
                        // alert("both categrical working...");
                        if (this.sheetsData[this.currentActiveSheetIndex].graphtype != "pivot") {
                            this.sheetsData[this.currentActiveSheetIndex].graphtype = "table";
                        }
                        this.fetchData();
                        this.loader_status = false;

                    }
                    if (xnumeric == true && ynumeric == true && (getxdta.length == 1 && getydta.length == 1)) {
                        // this.sheetsData[this.currentActiveSheetIndex].graphtype = "scatter";
                        // this.sheetsData[this.currentActiveSheetIndex].chartoptions.series[0].type = "scatter";
                        // this.bothnumeric(this.currentActiveSheetIndex);
                        // this.scatterdm = true;

                        if (this.sheetsData[this.currentActiveSheetIndex].graphtype == "scatter") {
                            this.bothnumeric(this.currentActiveSheetIndex, this.sheetsData[this.currentActiveSheetIndex].postagglist, this.sheetsData[this.currentActiveSheetIndex].expresslist);
                        }
                        else {
                            this.numandcategory(this.currentActiveSheetIndex, getydta, getxdta, "ycat", this.sheetsData[this.currentActiveSheetIndex].postagglist, this.sheetsData[this.currentActiveSheetIndex].expresslist);
                        }
                        this.loader_status = false;
                    }
                }
                if (noofcolumns > 1 || noofrows > 1) {
                    // alert("multiple x y chart working...");
                    // if(this.sheetsData[this.currentActiveSheetIndex].graphtype != "pivot"){
                    //     this.sheetsData[this.currentActiveSheetIndex].graphtype ="table";
                    // }
                    // this.fetchData();


                    let xcolmn = [];
                    let ycolmn = [];

                    getxdta.forEach((xdta) => {
                        xcolmn.push(xdta.type);
                    })

                    getydta.forEach((ydta) => {
                        ycolmn.push(ydta.type);
                    })

                    if ((!xcolmn.includes("numerical") && xcolmn.length == 1) && (!ycolmn.includes("categories") && ycolmn.length > 1)) {
                        this.multiplechart(this.currentActiveSheetIndex, getxdta, getydta, "colcat", this.sheetsData[this.currentActiveSheetIndex].postagglist, this.sheetsData[this.currentActiveSheetIndex].expresslist);
                    }
                    else if ((!xcolmn.includes("categories") && xcolmn.length > 1) && (!ycolmn.includes("numerical") && ycolmn.length == 1)) {
                        this.multiplechart(this.currentActiveSheetIndex, getydta, getxdta, "rwcat", this.sheetsData[this.currentActiveSheetIndex].postagglist, this.sheetsData[this.currentActiveSheetIndex].expresslist);
                    }
                    else if ((!xcolmn.includes("numerical") && xcolmn.length <= 3) && (!ycolmn.includes("categories") && ycolmn.length > 0)) {
                        this.multipledimensioncharts(this.currentActiveSheetIndex, getxdta, getydta, "colcat", this.sheetsData[this.currentActiveSheetIndex].postagglist, this.sheetsData[this.currentActiveSheetIndex].expresslist);
                    }
                    else if ((!xcolmn.includes("categories") && xcolmn.length > 0) && (!ycolmn.includes("numerical") && ycolmn.length <= 3)) {
                        this.multipledimensioncharts(this.currentActiveSheetIndex, getydta, getxdta, "rwcat", this.sheetsData[this.currentActiveSheetIndex].postagglist, this.sheetsData[this.currentActiveSheetIndex].expresslist);
                    }
                    else {
                        if (this.sheetsData[this.currentActiveSheetIndex].graphtype != "pivot") {
                            this.sheetsData[this.currentActiveSheetIndex].graphtype = "table";
                        }
                        this.fetchData();
                    }

                    // this.loader_status = false;
                }
            }


        },

        /*------------------- fetch data functions end --------------------------*/

        setgraphviewicon(xtyp, ytyp) {

            this.piedm = false;
            this.barhdm = false;
            this.barvdm = false;
            this.linedm = false;
            this.scatterdm = false;
            this.treedm = false;
            this.ganttdm = false;
            this.kpidm = false;
            this.pivotdm = false;
            this.tabledm = false;

            if (xtyp.length == 1 && ytyp.length == 1) {
                if ((xtyp[0] == "categories" && ytyp[0] == "numerical") || (ytyp[0] == "categories" && xtyp[0] == "numerical")) {
                    this.piedm = true;
                    this.barhdm = true;
                    this.barvdm = true;
                    this.linedm = true;
                    this.pivotdm = true;
                    this.tabledm = true;
                }
                if (xtyp[0] == "numerical" && ytyp[0] == "numerical") {
                    this.piedm = true;
                    this.barhdm = true;
                    this.barvdm = true;
                    this.linedm = true;
                    this.scatterdm = true;
                    this.pivotdm = true;
                    this.tabledm = true;
                }
                if (xtyp[0] == "categories" && ytyp[0] == "categories") {
                    // alert("both category");
                    this.pivotdm = true;
                    this.tabledm = true;
                }

            }

            if ((ytyp.length == 0 && xtyp.length == 1) || (xtyp.length == 0 && ytyp.length == 1)) {
                if (xtyp[0] == "numerical" && ytyp.length == 0) {
                    this.piedm = true;
                    this.barhdm = true;
                    this.barvdm = true;
                    this.linedm = true;
                    this.scatterdm = true;
                    this.pivotdm = true;
                    this.tabledm = true;
                }
                if (ytyp[0] == "numerical" && xtyp.length == 0) {
                    this.piedm = true;
                    this.barhdm = true;
                    this.barvdm = true;
                    this.linedm = true;
                    this.scatterdm = true;
                    this.pivotdm = true;
                    this.tabledm = true;
                }
                if (xtyp[0] == "categories" && ytyp.length == 0) {
                    // this.piedm = true;
                    // this.barhdm = true;
                    // this.barvdm = true;
                    // this.linedm = true;
                    // this.scatterdm = true;
                    this.pivotdm = true;
                    this.tabledm = true;
                }
                if (ytyp[0] == "categories" && xtyp.length == 0) {
                    // this.piedm = true;
                    // this.barhdm = true;
                    // this.barvdm = true;
                    // this.linedm = true;
                    // this.scatterdm = true;
                    this.pivotdm = true;
                    this.tabledm = true;
                }
            }

            if (xtyp.length > 1 || ytyp.length > 1) {
                // this.piedm = true;
                this.barhdm = true;
                this.barvdm = true;
                this.linedm = true;
                this.scatterdm = true;
                this.pivotdm = true;
                this.tabledm = true;
            }

            this.hideactivegraphtype();

        },
        hideactivegraphtype() {
            if (this.sheetsData[this.currentActiveSheetIndex].getxdata.length > 0 || this.sheetsData[this.currentActiveSheetIndex].getydata.length > 0) {
                if (this.sheetsData[this.currentActiveSheetIndex].graphtype == "table") {
                    this.tabledm = false;
                }
            }
            if (this.sheetsData[this.currentActiveSheetIndex].graphtype == "pivot") {
                this.pivotdm = false;
            }
            if (this.sheetsData[this.currentActiveSheetIndex].graphtype == "pie") {
                this.piedm = false;
            }
            if (this.sheetsData[this.currentActiveSheetIndex].graphtype == "line" && (this.sheetsData[this.currentActiveSheetIndex].chartoptions.xAxis.type == "value")) {
                this.linedm = false;
                this.barhdm = false;
            }
            if (this.sheetsData[this.currentActiveSheetIndex].graphtype == "line" && (this.sheetsData[this.currentActiveSheetIndex].chartoptions.yAxis.type == "value")) {
                this.linedm = false;
                this.barvdm = false;
            }
            if (this.sheetsData[this.currentActiveSheetIndex].graphtype == "scatter") {
                this.scatterdm = false;
            }
            if ((this.sheetsData[this.currentActiveSheetIndex].graphtype == "bar") && (this.sheetsData[this.currentActiveSheetIndex].chartoptions.xAxis.type == "value")) {
                this.barvdm = false;
            }
            if ((this.sheetsData[this.currentActiveSheetIndex].graphtype == "bar") && (this.sheetsData[this.currentActiveSheetIndex].chartoptions.yAxis.type == "value")) {
                this.barhdm = false;
            }

        },
        isOverflown(element) {
            return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
        }
    }

}

import 'vue-histogram-slider/dist/histogram-slider.css';
</script>
<style scoped>
.chart {
    height: 60vh;
}
</style>
